import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from "react";
import "./index.css";
import { Box, TextField, Tooltip, tooltipClasses } from "@mui/material";
import SearchBar from "../../../components/_utils/search/SearchBar";
import {
  setToggle,
  resetToggle,
  internalTxnViewAll,
} from "../../../redux/features/internalTransaction/internalTransactionViewAll";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount.js";
import DataCountPopup from "../../../components/_utils/DataCountPopup/index";
import { formatAccountNumber } from "../../../components/_utils/FormatAccountNumber";
import { internalSingleApproval } from "../../../redux/features/internalTransaction/internalTransactionSingleApproval.js";
import { checkUsertoken } from "../../../redux/features/login/login";
import { useDispatch, useSelector } from "react-redux";
import closeBtnImg from "../../../assets/CloseIcons/close_with_blue_circle.svg";
import rentalImg from "../../../assets/CategoryFilterIcons/rental.png";
import InfiniteScroll from "react-infinite-scroll-component";

import noaccount from "../../../assets/Beneficiary Icons/noaccfound.png";
import dataLoading from "../../../assets/Beneficiary Icons/inputloder.gif";
import { useLocation, useNavigate } from "react-router-dom";
import BeneTxnStatusPopover from "../../../components/BeneTransaction/BeneTxnStatusPopover";
import InternalTxnFilterSidebar from "../../../components/InternalTransactionMain/InternalTxnFilterSidebar";
import arrowup from "../../../assets/OwnerAccount/arrowup.png";
import arrowforward from "../../../assets/Beneficiary Icons/arrowForward.png";
import arrowdown from "../../../assets/OwnerAccount/arrowdown1.png";
import ArrowDown8 from "../../../assets/OwnerAccount/arrowdown8.png";
import FilterBlue from "../../../assets/OwnerAccount/filterblue.png";
import Filter from "../../../assets/ViewAllTransactions/filter.png";
import ArrowUp4 from "../../../assets/OwnerAccount/arrowdown4png.png";
import ArrowLeft from "../../../assets/OwnerAccount/arrowleft.png";
import ArrowRight from "../../../assets/OwnerAccount/arrowright.png";
import allChecker from "../../../assets/Beneficiary Icons/whitecheckmark.png";
import GridBlue from "../../../assets/OwnerAccount/gridview-blue.png";
import GridWhite from "../../../assets/OwnerAccount/gridview-white.png";
import ListBlue from "../../../assets/OwnerAccount/listview-blue.png";
import ListWhite from "../../../assets/OwnerAccount/listview-white.png";
import DistributionStatusPopover from "../../../components/BeneTransaction/DistributionStatusPopover";
import TxnSearchBar from "../../../components/_utils/TxnSearchBar";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import BeneTxnViewModal from "../../../components/BeneTransaction/BeneTxnViewModal";
import BeneTxnViewModalOwner from "../../../components/BeneTransaction/BeneTxnViewModalOwner";
import search_blue from "../../../assets/ViewAllTransactions/search_blue.png";
import search_white from "../../../assets/Approve transactions/search-white.png";
import closeIcon from "../../../../src/assets/General Icons/close.svg";
import { debounce } from "lodash";
import InternalTxnListViewCard from "../../../components/InternalTransactionMain/InternalTxnListViewCard";
import PaginationComponent from "../../../components/_utils/PaginationComponent/index";
import formatTotalBalance from "../../../utils/formatTotalBalance";
import OwnerAccountPopUp from "../../../components/BeneTransaction/OwnerAccountPopUp/index.jsx";

import ArrowUp from "../../../assets/OwnerAccount/arrowup.png";

import PrevIcon from "../../../assets/Beneficiary Icons/PreviousIcon.png";
import NextIcon from "../../../assets/Beneficiary Icons/NextIcon.png";
import InternalTxnViewAllCard from "../../../components/InternalTransactionMain/InternalTxnViewAllCard/index.jsx";
import { ConstructionOutlined } from "@mui/icons-material";
import { popup } from "leaflet";
import Loader from "../../../components/Loader/Index.jsx";

const InternalTransactionViewAll = (props) => {
  const {
    beneTxnInternalViewAccData,

    storedPage,
    setIsApprovalStatusSelected,
  } = useSelector((state) => state.beneTxnViewAccData);
  const { toggle } = useSelector((state) => state?.internalTxnViewall);
  const [showSecondContent, setShowSecondContent] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const [hideCarousal, setHideCaroursal] = useState(false);
  const [clearAllFilters, setClearAllFilters] = useState(null);
  const { BeneTxnDashBoardData, BeneTxnDashBoardLoading } = useSelector(
    (state) => state?.beneTxnViewAccData
  );
  const dropdownOptions =
    window.innerWidth < 1600
      ? [50, 100]
      : window.innerWidth < 2560
      ? [50, 100]
      : [50, 100];

  const location = useLocation();
  const navigate = useNavigate();
  // const approvalStatus = location.state ? location.state.approvalStatus : "";
  const {
    approvalStatus,
    DistributionDashBoard,
    isConfidentialFilter,
    isDashboradCheck,
  } = location.state || {};
  const [DashBoardisConfidentialFilter, SetDashBoardisConfidentialFilter] =
    useState(isConfidentialFilter);
  const isConfPopup = (props?.popup ?? "") === "Confidential Transaction";
  const category = location.state ? location.state.category : "";
  const [initCategory, setInitCategory] = useState(category ? category : "");
  const [DashBoardStatus, SetDashBoardStatus] = useState(approvalStatus);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [accumulatedData, setAccumulatedData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("all");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(
    category ? category : ""
  );
  const [searchUserText, setSearchUserText] = useState("");
  const [showDataCountPopup, setShowDataCountPopup] = useState(false);
  const [createdOnFilter, setCreatedOnFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [isPaymentMethod, setIsPaymentMethod] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [txnAmount, setTxnAmount] = useState("");
  const [txnAddDetails, setTxnAddDetails] = useState("");
  const [txnSearchType, setTxnSearchType] = useState("");
  const [txnAmountFilter, setTxnAmountFilter] = useState("");
  const [mainData, setMainData] = useState([]);
  // const [searchTxnAmount, setSearchTxnAmount] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [selectedBeneAccId, setSelectedBeneAccId] = useState(
    props?.OwnerDetail && props?.balanceType === "credit"
      ? props?.BeneAccId
        ? props?.BeneAccId
        : ""
      : ""
  );
  const [selectedOwnerAccId, setSelectedOwnerAccId] = useState(
    props?.OwnerDetail
      ? props?.OwnerDetail &&
        props?.balanceType === "debit" &&
        props?.owner_account_id
        ? props?.owner_account_id
        : ""
      : props?.owner_account_id
      ? props?.owner_account_id
      : ""
  );
  const [endDate, setEndDate] = useState(null);
  const [beneAccountId, setBeneAccountId] = useState(
    props?.OwnerDetail && props?.balanceType === "credit"
      ? props?.BeneAccId
        ? props?.BeneAccId
        : ""
      : ""
  );
  const [accountName, setAccountName] = useState("");
  const [accountNameOwner, setAccountNameOwner] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownPaymentOpen, setDropdownPaymentOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pageSize, setPageSize] = useState(dropdownOptions[0]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  // const [dropdownOptions, setDropdownOptions] = useState([50, 100]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isCardHovered, setIsCardHovered] = useState(false);
  const [currentStatusDistribution, setCurrentStatusDistribution] =
    useState("all");
  const [isDistributionSelected, setIsDistributionSelected] = useState(false);
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isViewModalOpenOwner, setIsViewModalOpenOwner] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isDistributionPopoverOpen, setIsDistributionPopoverOpen] =
    useState(false);
  const [inputValue, setInputValue] = useState(1);
  const [totalBalanceAmount, setTotalBalanceAmount] = useState(0);
  const [accountNumber, setAccountNumber] = useState(
    props?.owner_account_bank_acc_no ? props?.owner_account_bank_acc_no : ""
  );
  const [searchTerm, setSearchTerm] = useState("");

  const [IFSC, setIFSC] = useState("");
  const [bankLogo, setBankLogo] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");
  const [isConfidentialMethod, setIsConfidentialMethod] = useState(
    (DashBoardisConfidentialFilter === "Show only confidential" &&
      DashBoardStatus === "confidential") ||
      props?.internal_account_confidential === "Y"
      ? "Show only confidential"
      : props?.internal_account_confidential === "N"
      ? "Hide confidential"
      : props?.internal_account_confidential ?? ""
  );
  const [confidentialMethod, setConfidentialMethod] = useState(
    props?.internal_account_confidential === "Y"
      ? "Show only confidential"
      : props?.internal_account_confidential === "N"
      ? "Hide confidential"
      : props?.internal_account_confidential ?? ""
  );
  const [isDropdownConfidentialOpen, setIsDropdownConfidentialOpen] =
    useState(false);

  const [txnPayLoad, setTxnPayLoad] = useState({
    bankAccountName: "",
    beneAccountId:
      props?.OwnerDetail && props?.balanceType === "credit"
        ? props?.BeneAccId
          ? props?.BeneAccId
          : ""
        : "",
    bankAccountIFSC: "",
    bankAccountNo: props?.owner_account_bank_acc_no
      ? props?.owner_account_bank_acc_no
      : "",
    categories: "",
    pan: "",
    ownerAccountId: props?.OwnerDetail
      ? props?.OwnerDetail &&
        props?.balanceType === "debit" &&
        props?.owner_account_id
        ? props?.owner_account_id
        : ""
      : props?.owner_account_id
      ? props?.owner_account_id
      : "",
    amount: "",
    ownerbankAccountNo: "",
  });
  const dispatch = useDispatch();
  const showToast = () => {
    toast.info("Coming soon!", toast_position);
  };

  useEffect(() => {
    const newTotalPages = Math.ceil(totalCount / pageSize);
    setTotalPages(newTotalPages);

    setCurrentPage((prevPage) => {
      if (newTotalPages === 0) {
        return 1;
      }
      if (prevPage > newTotalPages) {
        return newTotalPages;
      }
      return prevPage;
    });
  }, [totalCount, pageSize]);

  const setCurrentWidth = () => {
    setSelectedValue(dropdownOptions[0]);
    setPageSize(dropdownOptions[0]);
  };
  window.onresize = setCurrentWidth;

  // useEffect(() => {
  //   setDropdownOptions([50, 100].filter((value) => value !== selectedValue));
  // }, [selectedValue]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (scrollRef) {
      if (scrollRef?.current?.scrollWidth > scrollRef?.current?.clientWidth) {
        setHideCaroursal(true);
      } else {
        setHideCaroursal(false);
      }
    }
  }, [
    currentCategory,
    startDateFilter,
    endDateFilter,
    selectedBeneAccId,
    isPaymentMethod,
    accountNumber,
    txnAmountFilter,
    isConfidentialMethod,
  ]);

  const beneTxnNext = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 1024,
        behavior: "smooth",
      });
    }
    setShowSecondContent(!showSecondContent);
    setDisableNext(true);
    setDisablePrev(false);
  };

  const beneTxnPrev = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -1024,
        behavior: "smooth",
      });
    }
    setShowSecondContent(!showSecondContent);
    setDisablePrev(true);
    setDisableNext(false);
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    setPageSize(value);
    setCurrentPage(Number(storedPage));
    setAccumulatedData([]);
    toggleDropdown();
  };

  const lastElementRef = useRef();
  const filterRef = useRef(null);
  const viewModalRef = useRef(null);
  const settlementDropdownRef = useRef(null);
  const categoryDropdownRef = useRef(null);
  const paymentDropdownRef = useRef(null);

  const dateRef = useRef(null);
  const dropdownRef = useRef(null);
  const searchBarRef = useRef(null);
  const searchBarInputRef = useRef(null);

  const sidebarButtonRef = useRef(null);
  const scrollRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const confidentialDropdownRef = useRef(null);

  const handleFilterCategory = (selectedCategoryName) => {
    setCurrentCategory(selectedCategoryName);
  };

  const toggleSidebar = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    setIsSidebarOpen(!isSidebarOpen);

    // setShowDatePicker(false);
  };
  const handleOpenViewModal = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    setIsViewModalOpen(true);
  };
  const handleOpenViewModalOwner = () => {
    setIsViewModalOpenOwner(true);
  };

  const handleCloseViewModalOwner = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    setIsViewModalOpenOwner(false);
  };
  const handleCloseViewModal = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    setIsViewModalOpen(false);
  };

  const handleAccountSelection = (
    accountName,
    accountNumber,
    ifsc,
    beneAccountId
  ) => {
    setAccountName(accountName);
    setAccountNumber(accountNumber);
    setIFSC(ifsc);
    setBeneAccountId(beneAccountId);

    setTxnPayLoad({
      ...txnPayLoad,
      bankAccountName: accountName,
      bankAccountNo: accountNumber,
      bankAccountIFSC: ifsc,
      beneAccountId: beneAccountId,
    });
    handleCloseViewModal();
  };

  const handleAmountSelected = (amount) => {
    setTxnPayLoad({
      ...txnPayLoad,
      amount: txnAmountFilter,
    });
  };

  const handleAccountSelectionOwner = (
    accountName,
    accountNumber,
    ifsc,
    beneAccountId
  ) => {
    setTxnPayLoad({
      ...txnPayLoad,
      ownerAccountId: beneAccountId,
      ownerbankAccountNo: accountNumber,
    });
    handleCloseViewModalOwner();
  };

  useEffect(() => {
    const handleAction = async () => {
      if (currentStatus === "require approval") {
        try {
          setLoading(true);
          dispatch(checkUsertoken());
          const responseApproval = await dispatch(
            internalSingleApproval({
              page_no: currentPage,
              page_item_count: pageSize,
              category: currentCategory,
            })
          );

          const newApprovalData = responseApproval?.payload?.transactions || [];
          setMainData(responseApproval?.payload);
          setAccumulatedData(newApprovalData);

          setTotalCount(responseApproval?.payload?.total_count || 0);
          setLoading(false);
          setShowDataCountPopup(true);
        } catch (error) {
          setAccumulatedData([]);
          setMainData([]);
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          let queryParams = {
            page_no: currentPage,
            page_item_count: pageSize,
          };

          const addParamsBasedOnSelection = () => {
            if (
              currentStatusDistribution &&
              currentStatusDistribution !== "all"
            ) {
              queryParams.distribution_lifecycle_status =
                currentStatusDistribution;
            }
            if (
              DistributionDashBoard === false &&
              DashBoardStatus !== "all" &&
              DashBoardStatus !== "confidential"
            ) {
              queryParams.lifecycle_status = DashBoardStatus;
            }
            if (DashBoardStatus !== "all" && DistributionDashBoard === true) {
              queryParams.distribution_lifecycle_status = DashBoardStatus;
            }

            if (currentStatus && currentStatus !== "all") {
              queryParams.lifecycle_status = currentStatus;
            }

            if (currentCategory && currentCategory !== "Select category") {
              queryParams.category = currentCategory;
            }
            if (
              startDateFilter &&
              endDateFilter &&
              !isNaN(Number(startDateFilter)) &&
              !isNaN(Number(endDateFilter))
            ) {
              queryParams.start_date = startDateFilter + 86400;
              queryParams.end_date = endDateFilter + 86400;
            }
            if (isPaymentMethod && isPaymentMethod !== "All") {
              queryParams.payment_method = isPaymentMethod;
            }
            if (
              isConfidentialMethod &&
              isConfidentialMethod !== "Default (all transactions)"
            ) {
              queryParams.confidential_transaction = isConfidentialMethod;
            }

            if (txnSearchType === "amount") {
              if (txnAmount !== "") {
                queryParams.amount = txnAmount;
              }
            }
            if (txnSearchType === "additional") {
              if (txnAddDetails !== "") {
                queryParams.category_value = txnAddDetails;
                queryParams.category_name = "value";
              }
            }
            if (txnAmountFilter !== "") {
              queryParams.amount = txnAmountFilter;
            }

            if (selectedOwnerAccId !== "") {
              queryParams.owner_account_id = selectedOwnerAccId;
            }
            if (selectedBeneAccId !== "") {
              queryParams.internal_account_id = selectedBeneAccId;
            }
          };

          addParamsBasedOnSelection();

          dispatch(checkUsertoken());

          const response = await dispatch(internalTxnViewAll(queryParams));

          const newData = response?.payload?.transactions || [];
          setMainData(response?.payload);
          setAccumulatedData(newData);
          setTotalBalanceAmount(Number(response?.payload?.total_amount || 0));
          setTotalCount(response?.payload?.total_count || 0);
          setLoading(false);
          setShowDataCountPopup(true);
        } catch (error) {
          setAccumulatedData([]);
          setMainData([]);
          setLoading(false);
        }
      }
    };
    handleAction();
  }, [
    dispatch,
    currentPage,
    currentStatus,
    currentStatusDistribution,
    currentCategory,
    startDateFilter,
    endDateFilter,
    pageSize,
    isPaymentMethod,
    txnAmount,
    txnAddDetails,
    txnAmountFilter,
    selectedBeneAccId,
    selectedOwnerAccId,
    toggle,
    isConfidentialMethod,
  ]);

  useEffect(() => {
    setInputValue(String(currentPage).padStart(2, "0"));
  }, [currentPage, totalPages]);

  useEffect(() => {
    setCurrentPage(1);
    setInputValue("1");
    dispatch(resetToggle());
  }, []);

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const getAccStatus = (item, ele) => {
    if (ele === "distribution") {
      setCurrentStatusDistribution(item);
      setIsDistributionSelected(item !== "all");
    } else if (ele === "status") {
      setCurrentStatus(item);
      setIsDistributionSelected(false);
      setIsStatusSelected(item !== "all");
    }
  };

  const handleFilterSticker = () => {
    setCurrentCategory("");
    setInitCategory("");
    navigate(`/transaction/beneficiary_transaction/view_all`, {
      state: { approvalStatus: "all", category: null },
    });
  };
  useEffect(() => {
    updateIsFilterApplied();
  }, [
    currentCategory,
    startDateFilter,
    endDateFilter,
    txnAmountFilter,
    isPaymentMethod,
    selectedBeneAccId,
    selectedOwnerAccId,
    isConfidentialMethod,
  ]);

  const updateIsFilterApplied = () => {
    setIsFilterApplied(
      !!currentCategory ||
        !!(startDateFilter && endDateFilter) ||
        !!isPaymentMethod ||
        !!selectedBeneAccId ||
        txnAmountFilter ||
        isConfidentialMethod ||
        selectedOwnerAccId
    );
  };
  const getArrowIcon = (isSidebarOpen, isFilterApplied) => {
    if (isSidebarOpen) {
      return isFilterApplied ? ArrowUp4 : arrowup;
    } else {
      return isFilterApplied ? ArrowDown8 : arrowdown;
    }
  };
  const handleFilterDateRange = (startDate, endDate) => {
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
  };
  const handlePaymentMethod = (selectedSettlement) => {
    setIsPaymentMethod(selectedSettlement);
  };

  const handleConfidentialMethod = (selectedConfidential) => {
    setIsConfidentialMethod(selectedConfidential);
  };

  const handleFilterAmountMethod = () => {
    setTxnAmountFilter("");
    setTxnPayLoad({
      ...txnPayLoad,
      amount: "",
    });
  };

  const handleFilterDateSticker = () => {
    setStartDate("");
    setStartDateFilter("");
    setEndDateFilter("");
    setEndDate("");
  };

  const handleFilterBeneAccSticker = () => {
    if (
      props?.txnHistorytype !== "ownerDetailTxnHistory" &&
      props?.balanceType !== "credit"
    ) {
      setBeneAccountId(null);
      setSelectedBeneAccId("");
      setAccountName("");
    }
  };
  const handleFilterPaymentSticker = () => {
    setIsPaymentMethod("");
    setPaymentMethod("");
  };

  const handleFilterConfidentialSticker = () => {
    if (
      (props?.txnHistorytype ?? "internalTxnHistory") ===
        "internalTxnHistory" ||
      (props?.OwnerDetail ?? true)
    ) {
      setIsConfidentialMethod("");
      setConfidentialMethod("");
    }
  };

  const handleFilterPaymentStickerOwner = () => {
    if (
      props?.txnHistorytype !== "internalTxnHistory" &&
      props?.txnHistorytype !== "ownerDetailTxnHistory"
    ) {
      setAccountNumber("");
      setSelectedOwnerAccId("");
      setTxnPayLoad({
        ...txnPayLoad,
        ownerbankAccountNo: "",
        ownerAccountId: "",
      });
    } else if (
      props?.txnHistorytype === "ownerDetailTxnHistory" &&
      props?.balanceType === "credit"
    ) {
      setSelectedOwnerAccId("");
      setTxnPayLoad({
        ...txnPayLoad,
        ownerbankAccountNo: "",
        ownerAccountId: "",
      });
    }
  };
  const handleSearch = (text) => {
    if (text) {
      setTxnAddDetails(text);
    }
  };
  const handlePaste = (e) => {
    // e.preventDefault();
    if (txnSearchType === "amount") {
      setTxnAmount(e.target.value);
    }
    if (txnSearchType === "additional") {
      setTxnAddDetails(e.target.value);
    }
  };
  const handleTxnAmountSearch = (selectedTxnId) => {
    setTxnAmount(selectedTxnId);
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isViewModalOpen || isViewModalOpenOwner) {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
          return;
        }
        return;
      }

      if (
        (settlementDropdownRef.current &&
          settlementDropdownRef.current.contains(event.target)) ||
        (categoryDropdownRef.current &&
          categoryDropdownRef.current.contains(event.target)) ||
        (dateRef.current && dateRef.current.contains(event.target)) ||
        (paymentDropdownRef.current &&
          paymentDropdownRef.current.contains(event.target)) ||
        (confidentialDropdownRef.current &&
          confidentialDropdownRef.current.contains(event.target))
      ) {
        // setIsSidebarOpen(false);
        setIsDropdownOpen(false);
        setDropdownOpen(false);
        // setShowDatePicker(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isViewModalOpen, isViewModalOpenOwner]);

  useEffect(() => {
    const handleDropDown = (e) => {
      if (filterRef.current && filterRef.current.contains(e.target)) {
      } else if (
        sidebarButtonRef.current &&
        sidebarButtonRef.current.contains(e.target)
      ) {
      } else if (
        searchBarRef.current &&
        searchBarRef.current.contains(e.target)
      ) {
      } else {
        setIsSidebarOpen(false);
        setShowSearchBar(false);
      }
    };
    document.addEventListener("click", handleDropDown);
  }, [filterRef, searchBarRef]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1;
        setInputValue(String(newPage).padStart(String(totalPages).length, "0"));
        return newPage;
      });
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1;
        setInputValue(String(newPage).padStart(String(totalPages).length, "0"));
        return newPage;
      });
    }
  };
  const marginTop =
    (props?.popup !== "" || props?.OwnerDetail) &&
    showSearchBar &&
    isFilterApplied
      ? "16.4rem"
      : (props?.popup !== "" || props?.OwnerDetail) && isFilterApplied
      ? "12.7rem"
      : (props?.popup !== "" || props?.OwnerDetail) && showSearchBar
      ? "14.8rem"
      : isFilterApplied && showSearchBar
      ? "17.8rem"
      : isFilterApplied
      ? "13.8rem"
      : isPopoverOpen || isDistributionPopoverOpen
      ? "10rem"
      : showSearchBar
      ? "12.8rem"
      : // : toggle
        // ? "4rem"
        "9.5rem";

  const getIcon = () => {
    if (showSearchBar) {
      return txnAddDetails !== "" ? ArrowUp4 : arrowup;
    } else {
      return txnAddDetails !== "" ? ArrowDown8 : arrowdown;
    }
  };
  const handlePageChange = useCallback(
    debounce((page) => {
      const pageNumber = Number(page);
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      } else if (pageNumber > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(1);
      }
    }, 300),

    [totalPages]
  );

  const debouncedHandlePageChange = debounce((value) => {
    handlePageChange(value);
  }, 1000);

  const handleChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      const numericValue = Number(value);
      if (value === "") {
        setInputValue("");
        setCurrentPage(1);
      } else if (numericValue > totalPages) {
        setInputValue(
          String(totalPages).padStart(String(totalPages).length, "0")
        );
        setCurrentPage(totalPages);
      } else if (numericValue < 1) {
        setInputValue(1);
        setCurrentPage(1);
      } else {
        setInputValue(value);
        debouncedHandlePageChange(value);
      }
    }
  };

  let currentText = "";
  if (props?.internalAccount) {
    currentText =
      "All transactions related to the internal account are listed below.";
  } else if (props?.popup === "Transaction History") {
    currentText =
      "All transactions related to the destination account and source account are listed below.";
  } else {
    currentText =
      "All confidential transactions related to the destination account and source account are listed below.";
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const handleToggle = (newToggleValue) => {
    if (toggle !== newToggleValue) {
      const scrollTarget = scrollContainerRef.current || window;
      scrollTarget.scrollTo({ top: 0, behavior: "smooth" });

      if (!showSearchBar && !isSidebarOpen) {
        dispatch(setToggle(newToggleValue));
      }

      setCurrentPage(1);
    }
  };

  let hasFilterBeenApplied = !!(
    currentCategory !== "" ||
    isPaymentMethod ||
    txnAmountFilter ||
    selectedOwnerAccId ||
    isConfidentialMethod ||
    selectedBeneAccId ||
    (startDateFilter &&
      endDateFilter &&
      startDateFilter > 0 &&
      endDateFilter > 0 &&
      !isNaN(Number(startDateFilter)) &&
      !isNaN(Number(endDateFilter)))
  );

  return (
    <>
      {isMobile ? (
        <>
          <div
            id="beneScrollContainer"
            className={`bg-custom-appBack flex flex-col h-[96vh] mobileview`}
          >
            {isPopoverOpen && (
              <div className="acc_blackTxn_div bg-custom-backdrop"></div>
            )}
            {isSidebarOpen && (
              <div className="acc_blackTxn_div bg-custom-backdrop"></div>
            )}
            {isDistributionPopoverOpen && (
              <div className="acc_blackTxn_div bg-custom-backdrop"></div>
            )}

            {
              <div
                style={{
                  zIndex: "999",
                  width: "100%",
                  bottom: "0",
                  borderRadius: "0",
                  opacity:
                    isDistributionPopoverOpen || isSidebarOpen || isPopoverOpen
                      ? 0.1
                      : 1,
                }}
                className="fixed w-full bg-Blue_4 h-[50px]"
              >
                <PaginationComponent
                  handleSelect={handleSelect}
                  selectedValue={selectedValue}
                  data={mainData}
                  pageSize={pageSize}
                  dropdownOptions={dropdownOptions}
                  handlePagination={handlePagination}
                  currentPage={currentPage}
                />
              </div>
            }
            {isViewModalOpen && (
              <div
                style={{ zIndex: "2147483647" }}
                className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80"
              >
                <BeneTxnViewModal
                  setIsSidebarOpen={setIsSidebarOpen}
                  onClosed={handleCloseViewModal}
                  onAccountSelect={handleAccountSelection}
                  setTxnPayLoad={setTxnPayLoad}
                  txnPayLoad={txnPayLoad}
                  accountNumber={accountNumber}
                  IFSC={IFSC}
                  bankLogo={bankLogo}
                />
              </div>
            )}

            {isViewModalOpenOwner && (
              <div
                style={{ zIndex: "2147483647" }}
                className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80"
              >
                <OwnerAccountPopUp
                  setIsSidebarOpen={setIsSidebarOpen}
                  onClosed={handleCloseViewModalOwner}
                  onAccountSelect={handleAccountSelectionOwner}
                  setTxnPayLoad={setTxnPayLoad}
                  setOpenOwner={setIsViewModalOpenOwner}
                  txnPayLoad={txnPayLoad}
                  accountNumber={accountNumber}
                  IFSC={IFSC}
                  bankLogo={bankLogo}
                  flow={"txnViewAll"}
                  handlePopupClose={() => {
                    setIsViewModalOpenOwner(false);
                  }}
                />
              </div>
            )}

            <div
              className="bg-custom-appBack flex flex-col h-screen"
              id=" benetxnmain "
              style={{
                overflow: isSidebarOpen && "hidden",
                // opacity: isViewModalOpen || isViewModalOpenOwner ? 0.2 : 1,
              }}
            >
              <div className="beneTxnViewAllNavbar">
                <div className="beneTxnViewAllContent"></div>
                <div
                  className="beneTxnViewAllSec1"
                  style={{
                    // height: showSearchBar ? "292px" : "150px",
                    transition: showSearchBar
                      ? "all 0.2s ease-in"
                      : "all 0.2s ease-in",
                  }}
                >
                  <div
                    className="beneTxnViewAllSec2"
                    style={{
                      width: "100%",
                      top: "0",
                      background: "transparent",
                    }}
                  >
                    <div
                      className="beneTxnViewAll_middleBar"
                      style={{
                        borderBottom: showSearchBar
                          ? "1px solid var(--Blue_3)"
                          : "",
                        padding: "0 15px",
                        height: props?.popup === undefined ? "70px" : "60px",
                        // height: showSearchBar ? "124px" : "102px",
                      }}
                    >
                      <div
                        className="totalBalance"
                        style={{
                          flexDirection: "row",
                          marginTop: "0",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          className="cursor-pointer flex items-center"
                          onClick={() => {
                            if (isSidebarOpen) {
                              setIsSidebarOpen(false);
                              return;
                            }
                            if (typeof props.popup !== "undefined") {
                              props?.onClose();
                            } else {
                              navigate(-1);
                            }
                          }}
                        >
                          <img
                            src={ArrowLeft}
                            alt=""
                            style={{
                              width: "25px",
                              height: "25px",
                              marginRight: "15px",
                            }}
                          />
                        </div>
                        {isSidebarOpen ? (
                          <span className="text-Primary_white">Filter</span>
                        ) : (
                          <div>
                            <div className="totalBalanceAmountDiv">
                              {typeof props?.popup !== "undefined" ? (
                                <span className="text-Primary_white">
                                  {props.popup ===
                                  "Confidential Transaction" ? (
                                    <span>Confidential transactions</span>
                                  ) : (
                                    <span>Transaction history</span>
                                  )}
                                </span>
                              ) : (
                                <>
                                  ₹{" "}
                                  {totalBalanceAmount !== undefined
                                    ? formatTotalBalance(totalBalanceAmount)
                                        .amount
                                    : "00.00"}
                                </>
                              )}
                            </div>
                            <p
                              style={{
                                width: "250px",
                                height: "17px",
                                font: "normal normal normal 12px/17px Open Sans",
                                color: "var(--Primary_grey)",
                              }}
                            >
                              <span>
                                {typeof props?.popup === "undefined" ? (
                                  <span>
                                    Total amount:{" "}
                                    {formatTotalBalance(totalBalanceAmount)
                                      .prefix == ""
                                      ? ""
                                      : "in " +
                                        formatTotalBalance(totalBalanceAmount)
                                          .prefix}
                                  </span>
                                ) : (
                                  <span>
                                    {`Total: ₹ ` +
                                      Number(
                                        totalBalanceAmount
                                      )?.toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                  </span>
                                )}
                              </span>
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="secondDivElement">
                        {/* <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title="Search"
                        >
                          <div
                            className={`beneTxn_viewAll_search_div ${
                              txnAddDetails !== "" ? "searched" : ""
                            } hover:bg-Blue_7`}
                            style={{
                              backgroundColor: "transparent",
                              borderRadius: "0",
                              borderRight: "2px solid var(--Blue_3)",
                              margin: "0",
                              height: "35px",
                              width: "35px",
                              opacity: isSidebarOpen ? "0.6" : "1",
                              cursor: isSidebarOpen ? "default" : "pointer",
                            }}
                            onClick={() => {
                              // if (!isSidebarOpen) {
                              //   setShowSearchBar((prev) => !prev);
                              //   setIsSidebarOpen(false);
                              // }
                              showToast();
                            }}
                          >
                            <img
                              className="bene_txn_search-image"
                              src={txnAddDetails ? search_blue : search_white}
                              alt=""
                            />

                            {showSearchBar && (
                              <>
                                <TxnSearchBar
                                  handleSearch={handleSearch}
                                  showSearchBar={showSearchBar}
                                  setShowSearchBar={setShowSearchBar}
                                  setCurrentPage={setCurrentPage}
                                  // setSearchTxnAmount={setSearchTxnAmount}
                                  txnAmount={txnAmount}
                                  txnAddDetails={txnAddDetails}
                                  setTxnAddDetails={setTxnAddDetails}
                                  setTxnAmount={setTxnAmount}
                                  txnSearchType={txnSearchType}
                                  setTxnSearchType={setTxnSearchType}
                                  handlePaste={handlePaste}
                                  toggleShowSearchBar={() => {
                                    setShowSearchBar(!showSearchBar);
                                  }}
                                  bgColor={"bg-custom-card"}
                                  bgHover={"hover:bg-custom-cardHover"}
                                  transactionHistorypopup={
                                    props?.popup || props?.OwnerDetail
                                  }
                                  flow={"internal"}
                                  searchBarInputRef={searchBarInputRef}
                                />
                              </>
                            )}
                          </div>
                        </Tooltip> */}

                        <div
                          ref={filterRef}
                          className={`beneTxn_viewall_filter_div ${
                            currentCategory !== "" ||
                            startDateFilter ||
                            endDateFilter ||
                            isPaymentMethod ||
                            selectedOwnerAccId ||
                            txnAmountFilter ||
                            selectedBeneAccId ||
                            isConfidentialMethod
                              ? "filtered"
                              : ""
                          }`}
                        >
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title="Filters"
                          >
                            <div
                              ref={sidebarButtonRef}
                              style={{
                                pointerEvents:
                                  isViewModalOpen || isViewModalOpenOwner
                                    ? "none"
                                    : "",
                                background: isFilterApplied
                                  ? "var(--Primary_white)"
                                  : "transparent",
                                boxShadow: "none",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: " 2px 10px",
                                height: "30px",
                                width: "30px",
                                opacity:
                                  showSearchBar ||
                                  currentStatus === "require approval"
                                    ? "0.6"
                                    : "1",
                                cursor:
                                  showSearchBar ||
                                  currentStatus === "require approval"
                                    ? "default"
                                    : "pointer",
                              }}
                              className="beneTxnViewAll_filter_container "
                              onClick={(e) => {
                                e.stopPropagation();
                                if (currentStatus !== "require approval") {
                                  toggleSidebar();
                                } else if (
                                  currentStatus === "require approval"
                                ) {
                                  toast.warn(
                                    "Filter restricted while status is require approval.",
                                    toast_position
                                  );
                                }
                                // setShowSearchBar(false);
                              }}
                            >
                              <Box className="beneTxnViewAll_filter_box">
                                <img
                                  src={isFilterApplied ? FilterBlue : Filter}
                                  alt=""
                                  className="bene-txn-filter-image"
                                />
                              </Box>
                            </div>
                          </Tooltip>
                          <InternalTxnFilterSidebar
                            setClearAllFilters={setClearAllFilters}
                            txnHistorytype={props?.txnHistorytype}
                            detailConfidential={
                              props?.internal_account_confidential
                            }
                            isOpen={isSidebarOpen}
                            onClose={toggleSidebar}
                            sendCategory={handleFilterCategory}
                            handleFilterDateRange={handleFilterDateRange}
                            setPage={setCurrentPage}
                            setIsSidebarOpen={setIsSidebarOpen}
                            initCategory={initCategory}
                            setInitCategory={setInitCategory}
                            isPaymentMethod={isPaymentMethod}
                            setEndDateFilter={setEndDateFilter}
                            setStartDateFilter={setStartDateFilter}
                            sendPaymentMethod={handlePaymentMethod}
                            setTxnPayLoad={setTxnPayLoad}
                            startDateFilter={startDateFilter}
                            endDateFilter={endDateFilter}
                            handleAmountSelected={handleAmountSelected}
                            txnPayLoad={txnPayLoad}
                            isSidebarOpen={isSidebarOpen}
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                            selectedBeneAccId={selectedBeneAccId}
                            setSelectedBeneAccId={setSelectedBeneAccId}
                            beneAccountId={beneAccountId}
                            setBeneAccountId={setBeneAccountId}
                            accountName={accountName}
                            setAccountName={setAccountName}
                            showDatePicker={showDatePicker}
                            setShowDatePicker={setShowDatePicker}
                            isFilterApplied={isFilterApplied}
                            setIsFilterApplied={setIsFilterApplied}
                            settlementDropdownRef={settlementDropdownRef}
                            paymentDropdownRef={paymentDropdownRef}
                            categoryDropdownRef={categoryDropdownRef}
                            dateRef={dateRef}
                            dropdownOpen={dropdownOpen}
                            setIsDropdownOpen={setIsDropdownOpen}
                            isDropdownOpen={isDropdownOpen}
                            setDropdownOpen={setDropdownOpen}
                            isDropdownPaymentOpen={isDropdownPaymentOpen}
                            setDropdownPaymentOpen={setDropdownPaymentOpen}
                            setTxnAmountFilter={setTxnAmountFilter}
                            txnAmountFilter={txnAmountFilter}
                            onClosed={handleCloseViewModal}
                            onAccountSelect={handleAccountSelection}
                            isViewModalOpen={isViewModalOpen}
                            handleOpenViewModal={handleOpenViewModal}
                            viewModalRef={viewModalRef}
                            handleOpenViewModalOwner={handleOpenViewModalOwner}
                            selectedOwnerAccId={selectedOwnerAccId}
                            setSelectedOwnerAccId={setSelectedOwnerAccId}
                            accountNumber={accountNumber}
                            setAccountNumber={setAccountNumber}
                            tempStartDate={tempStartDate}
                            tempEndDate={tempEndDate}
                            setTempEndDate={setTempEndDate}
                            setTempStartDate={setTempStartDate}
                            currentStatus={currentStatus}
                            setCurrentStatus={setCurrentStatus}
                            balanceType={props?.balanceType}
                            internalAccount={props?.internalAccount}
                            isConfidentialMethod={isConfidentialMethod}
                            sendConfidentialMethod={handleConfidentialMethod}
                            confidentialMethod={confidentialMethod}
                            setConfidentialMethod={setConfidentialMethod}
                            confidentialDropdownRef={confidentialDropdownRef}
                            isDropdownConfidentialOpen={
                              isDropdownConfidentialOpen
                            }
                            setIsDropdownConfidentialOpen={
                              setIsDropdownConfidentialOpen
                            }
                            popup={props.popup}
                            isConfidentialFilter={isConfidentialFilter}
                            DashBoardStatus={DashBoardStatus}
                            DashBoardisConfidentialFilter={
                              DashBoardisConfidentialFilter
                            }
                            SetDashBoardisConfidentialFilter={
                              SetDashBoardisConfidentialFilter
                            }
                            isDashboradCheck={isDashboradCheck}
                          />
                        </div>
                      </div>
                    </div>
                    {!isSidebarOpen && (
                      <>
                        <div
                          style={{
                            display:
                              props?.OwnerDetail && !props?.internalAccount
                                ? "flex"
                                : "none",
                          }}
                          className="h-[35px] w-full bg-Blue_5"
                        >
                          <div
                            onClick={() => {
                              props?.setBalanceType("credit");
                              setTimeout(() => {
                                props?.setType("");
                              }, 110);
                              setTimeout(() => {
                                props?.setType("Internal transaction");
                              }, 200);
                            }}
                            style={{
                              backgroundColor:
                                props?.balanceType === "credit"
                                  ? "var(--Primary_white)"
                                  : "var(--Blue_5)",
                              fontWeight:
                                props?.balanceType == "credit" ? "600" : "400",
                              color:
                                props?.balanceType == "credit"
                                  ? "var(--Primary_black)"
                                  : "var(--Primary_white)",
                            }}
                            className="w-full cursor-pointer flex justify-center items-center"
                          >
                            Credit
                          </div>
                          <div
                            onClick={() => {
                              props?.setBalanceType("debit");
                              setTimeout(() => {
                                props?.setType("");
                              }, 110);
                              setTimeout(() => {
                                props?.setType("Internal transaction");
                              }, 200);
                            }}
                            style={{
                              backgroundColor:
                                props?.balanceType === "debit"
                                  ? "var(--Primary_white)"
                                  : "var(--Blue_5)",
                              fontWeight:
                                props?.balanceType == "debit" ? "600" : "400",
                              color:
                                props?.balanceType == "debit"
                                  ? "var(--Primary_black)"
                                  : "var(--Primary_white)",
                            }}
                            className="w-full cursor-pointer flex justify-center items-center"
                          >
                            Debit
                          </div>
                        </div>

                        <div
                          className="beneTxnViewAllWholeSection"
                          style={{
                            width: "100%",
                            justifyContent: "space-around",
                          }}
                        >
                          <div
                            className="beneTxnViewAllParentdiv"
                            style={{ width: "48%" }}
                          >
                            <div className="beneTxnViewAllchild1">
                              <BeneTxnStatusPopover
                                typepop={props?.typepop}
                                data={accumulatedData}
                                getAccStatus={getAccStatus}
                                DashBoardStatusData={DashBoardStatus}
                                DistributionStatusData={DistributionDashBoard}
                                SetDashBoardStatusData={SetDashBoardStatus}
                                selectedStatus={currentStatus}
                                setPage={setCurrentPage}
                                setIsApprovalStatusSelected={
                                  setIsApprovalStatusSelected
                                }
                                isDistributionSelected={isDistributionSelected}
                                setIsDistributionSelected={
                                  setIsDistributionSelected
                                }
                                isStatusSelected={isStatusSelected}
                                setIsStatusSelected={setIsStatusSelected}
                                setIsPopoverOpen={setIsPopoverOpen}
                                isPopoverOpen={isPopoverOpen}
                                currentCategory={currentCategory}
                                setCurrentCategory={setCurrentCategory}
                                setPaymentMethod={setPaymentMethod}
                                setTempEndDate={setTempEndDate}
                                setTempStartDate={setTempStartDate}
                                setSelectedOwnerAccId={setSelectedOwnerAccId}
                                setTxnAmountFilter={setTxnAmountFilter}
                                setSelectedBeneAccId={setSelectedBeneAccId}
                                sendPaymentMethod={handlePaymentMethod}
                                setStartDateFilter={setStartDateFilter}
                                setEndDateFilter={setEndDateFilter}
                                setInitCategory={setInitCategory}
                                setTxnPayLoad={setTxnPayLoad}
                                txnPayLoad={txnPayLoad}
                                setBeneAccountId={setBeneAccountId}
                                setEndDate={setEndDate}
                                setStartDate={setStartDate}
                                setAccountName={setAccountName}
                                isSidebarOpen={isSidebarOpen}
                                showSearchBar={showSearchBar}
                                txnHistorytype={props?.txnHistorytype}
                                setConfidentialMethod={setConfidentialMethod}
                                sendConfidentialMethod={
                                  handleConfidentialMethod
                                }
                                isFilterApplied={isFilterApplied}
                              />
                            </div>
                          </div>

                          <div
                            className="ver__divider"
                            style={{ height: "50px" }}
                          ></div>

                          <div
                            className="beneTxnViewallAmountSection"
                            style={{
                              height: "59px",
                              marginTop: "0",
                              width: "48%",
                            }}
                          >
                            <div className="beneTxnViewallTotalAmount">
                              <DistributionStatusPopover
                                typepop={props?.typepop}
                                setAccountName={setAccountName}
                                setBeneAccountId={setBeneAccountId}
                                setSelectedBeneAccId={setSelectedBeneAccId}
                                setConfidentialMethod={setConfidentialMethod}
                                data={accumulatedData}
                                getAccStatus={getAccStatus}
                                selectedStatus={currentStatusDistribution}
                                setPage={setCurrentPage}
                                isDistributionSelected={isDistributionSelected}
                                setIsDistributionSelected={
                                  setIsDistributionSelected
                                }
                                isStatusSelected={isStatusSelected}
                                setIsStatusSelected={setIsStatusSelected}
                                setIsDistributionPopoverOpen={
                                  setIsDistributionPopoverOpen
                                }
                                isDistributionPopoverOpen={
                                  isDistributionPopoverOpen
                                }
                                DashBoardStatusData={DashBoardStatus}
                                DistributionStatusData={DistributionDashBoard}
                                SetDashBoardStatusData={SetDashBoardStatus}
                                isSidebarOpen={isSidebarOpen}
                                showSearchBar={showSearchBar}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {currentCategory !== "" ||
              isPaymentMethod ||
              txnAmountFilter ||
              selectedOwnerAccId ||
              isConfidentialMethod ||
              selectedBeneAccId ||
              (startDateFilter &&
                endDateFilter &&
                startDateFilter > 0 &&
                endDateFilter > 0 &&
                !isNaN(Number(startDateFilter)) &&
                !isNaN(Number(endDateFilter))) ? (
                <div
                  className="Qmd:h-[1rem] bene_txn_filterdropdown-container-div"
                  style={{
                    height:
                      props?.txnHistorytype === "internalTxnHistory" &&
                      hideCarousal
                        ? "116px"
                        : props?.txnHistorytype === "internalTxnHistory"
                        ? ""
                        : hideCarousal && "auto",
                    // width:
                    //   props?.txnHistorytype === "internalTxnHistory"
                    //     ? "96%"
                    //     : null,
                    // left:
                    //   props?.txnHistorytype === "internalTxnHistory"
                    //     ? "2%"
                    //     : null,
                    // top:
                    //   props?.txnHistorytype === "internalTxnHistory"
                    //     ? "4%"
                    //     : null,
                    zIndex:
                      isDistributionPopoverOpen || isPopoverOpen ? "0" : "1000",
                  }}
                >
                  <div
                    style={{
                      marginTop:
                        isMobile &&
                        props.OwnerDetail &&
                        !props?.internalAccount &&
                        "3.5rem",
                    }}
                    className="bene_txn_filterdropdown_internal mt-[22px]"
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        overflow: "auto",
                        height: "50px",
                        backgroundColor: "var(--Blue_6)",
                        padding: "0 10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          //borderTop: "2px solid rgb(29, 58, 109)",
                        }}
                      >
                        <p
                          className="pr-2 w-[80px]"
                          style={{
                            whiteSpace: "nowrap",
                            flexShrink: 0,
                            color: " var(--Primary_white)",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          Filtered by :
                        </p>
                        <div
                          className="bene_txn_viewall_filter_div_left"
                          style={{
                            gap: "10px",
                            display: "flex",
                            maxWidth: "1024",
                            overflowX: "hidden",
                            whiteSpace: "nowrap",
                            marginLeft: "12px",
                          }}
                          ref={scrollRef}
                        >
                          {currentCategory && (
                            <>
                              <div className="bene_txn_viewall_filter_body px-2 mr-[5px]">
                                <div className="bene_txn_viewall_filter_img_div">
                                  <img src={rentalImg} alt={currentCategory} />
                                </div>
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        zIndex: 9999,
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={currentCategory}
                                >
                                  <p
                                    className="px-2 text-xs truncate"
                                    style={{
                                      flex: "1",
                                      width: "112px",
                                      color: "var(--Primary_white)",
                                      letterSpacing: " 0px",
                                      textTransform: "capitalize",
                                      opacity: "1",
                                    }}
                                  >
                                    {currentCategory}
                                  </p>
                                </Tooltip>
                                <div
                                  className="int_txn_viewall_close_btn"
                                  onClick={handleFilterSticker}
                                  style={{ cursor: "pointer" }}
                                >
                                  {" "}
                                  <img
                                    src={closeBtnImg}
                                    alt="close-btn"
                                    className="w-[12px] h-[12px]"
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {startDateFilter > 0 &&
                            endDateFilter > 0 &&
                            !isNaN(Number(startDateFilter)) &&
                            !isNaN(Number(endDateFilter)) && (
                              <div
                                className="bene_txn_viewall_filter_body px-2 mr-[10px]"
                                style={{
                                  width: "231px !important",
                                  opacity:
                                    currentStatus === "require approval"
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    currentStatus === "require approval"
                                      ? "default"
                                      : "pointer",
                                }}
                              >
                                <div className="px-2 text-xs">
                                  {formatDate(
                                    new Date(Number(startDateFilter))
                                  )}
                                </div>

                                {"-"}
                                <div className="px-2 text-xs">
                                  {formatDate(new Date(Number(endDateFilter)))}
                                </div>
                                <div
                                  className="int_txn_viewall_close_btn"
                                  onClick={handleFilterDateSticker}
                                  disabled={
                                    currentStatus === "require approval"
                                      ? true
                                      : false
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={closeBtnImg}
                                    alt="close-btn"
                                    className="w-[12px] h-[12px] cursor-pointer "
                                  />
                                </div>
                              </div>
                            )}

                          {selectedBeneAccId && (
                            <div
                              className="bene_txn_viewall_filter_body px-2 mr-[5px]"
                              style={{
                                opacity:
                                  currentStatus === "require approval"
                                    ? "0.5"
                                    : "1",
                                cursor:
                                  currentStatus === "require approval"
                                    ? "default"
                                    : "pointer",
                              }}
                            >
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      zIndex: 21479756468,
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={
                                  props?.OwnerDetail
                                    ? props?.owner_account_bank_acc_no
                                    : accountName?.toLowerCase()
                                }
                              >
                                <p
                                  className="px-2 text-xs"
                                  style={{
                                    flex: "1",
                                    width: "auto",
                                    color: "var(--Primary_white)",
                                    letterSpacing: " 0px",
                                    textTransform: "capitalize",
                                    opacity: "1",
                                  }}
                                >
                                  {props?.OwnerDetail
                                    ? formatAccountNumber(
                                        props?.owner_account_bank_acc_no
                                      )
                                    : accountName?.toLowerCase()}
                                </p>
                              </Tooltip>
                              <div
                                className="int_txn_viewall_close_btn"
                                onClick={() => {
                                  if (
                                    props?.txnHistorytype !==
                                    "internalTxnHistory"
                                  ) {
                                    return;
                                  } else {
                                    handleFilterBeneAccSticker();
                                  }
                                }}
                                disabled={
                                  currentStatus === "require approval"
                                    ? true
                                    : false
                                }
                                style={{
                                  cursor:
                                    props?.txnHistorytype ===
                                    "internalTxnHistory"
                                      ? "default"
                                      : "pointer",
                                  opacity:
                                    props?.txnHistorytype ===
                                    "internalTxnHistory"
                                      ? "0.4"
                                      : "1",
                                }}
                              >
                                {" "}
                                <img
                                  src={closeBtnImg}
                                  alt="close-btn"
                                  className="w-[12px] h-[12px]"
                                />
                              </div>
                            </div>
                          )}
                          {isPaymentMethod && (
                            <div
                              className="bene_txn_viewall_filter_body px-2"
                              style={{
                                opacity:
                                  currentStatus === "require approval"
                                    ? "0.5"
                                    : "1",
                                cursor:
                                  currentStatus === "require approval"
                                    ? "default"
                                    : "pointer",
                              }}
                            >
                              <p className="px-2 text-xs">{isPaymentMethod}</p>
                              <div
                                className="int_txn_viewall_close_btn"
                                onClick={() => {
                                  handleFilterPaymentSticker();
                                }}
                                style={{ cursor: "pointer" }}
                                disabled={
                                  currentStatus === "require approval"
                                    ? true
                                    : false
                                }
                              >
                                {" "}
                                <img
                                  src={closeBtnImg}
                                  alt="close-btn"
                                  className="w-[12px] h-[12px]"
                                />
                              </div>
                            </div>
                          )}
                          {selectedOwnerAccId && (
                            <div
                              className="bene_txn_viewall_filter_body px-2 mr-[5px]"
                              style={{
                                opacity:
                                  currentStatus === "require approval"
                                    ? "0.5"
                                    : "1",
                                cursor:
                                  currentStatus === "require approval"
                                    ? "default"
                                    : "pointer",
                              }}
                            >
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      zIndex: 99999999999,
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={formatAccountNumber(
                                  props?.OwnerDetail &&
                                    props?.balanceType === "credit"
                                    ? txnPayLoad?.ownerbankAccountNo
                                    : accountNumber
                                )}
                              >
                                <p
                                  className="px-2 text-xs"
                                  style={{
                                    flex: "1",
                                    width: "auto",
                                    color: "var(--Primary_white)",
                                    letterSpacing: " 0px",
                                    textTransform: "capitalize",
                                    opacity: "1",
                                  }}
                                >
                                  {formatAccountNumber(
                                    props?.OwnerDetail &&
                                      props?.balanceType === "credit"
                                      ? txnPayLoad?.ownerbankAccountNo
                                      : accountNumber
                                  )}
                                </p>
                              </Tooltip>
                              <div
                                className="int_txn_viewall_close_btn"
                                onClick={() => {
                                  if (
                                    props?.txnHistorytype !==
                                      "internalTxnHistory" &&
                                    props?.txnHistorytype !==
                                      "ownerDetailTxnHistory"
                                  ) {
                                    handleFilterPaymentStickerOwner();
                                  } else if (
                                    props?.txnHistorytype ===
                                      "ownerDetailTxnHistory" &&
                                    props?.balanceType === "credit"
                                  ) {
                                    handleFilterPaymentStickerOwner();
                                  } else {
                                    handleFilterPaymentStickerOwner();
                                  }
                                }}
                                style={{
                                  cursor:
                                    props?.txnHistorytype ===
                                      "internalTxnHistory" ||
                                    (props?.txnHistorytype ===
                                      "ownerDetailTxnHistory" &&
                                      props?.balanceType === "debit")
                                      ? "default"
                                      : "pointer",
                                  opacity:
                                    props?.txnHistorytype ===
                                      "internalTxnHistory" ||
                                    (props?.txnHistorytype ===
                                      "ownerDetailTxnHistory" &&
                                      props?.balanceType === "debit")
                                      ? "0.4"
                                      : "1",
                                }}
                                disabled={
                                  currentStatus === "require approval"
                                    ? true
                                    : false
                                }
                              >
                                {" "}
                                <img
                                  src={closeBtnImg}
                                  alt="close-btn"
                                  className="w-[12px] h-[12px]"
                                />
                              </div>
                            </div>
                          )}
                          {txnAmountFilter && (
                            <div
                              className="bene_txn_viewall_filter_body px-2"
                              style={{
                                opacity:
                                  currentStatus === "require approval"
                                    ? "0.5"
                                    : "1",
                                cursor:
                                  currentStatus === "require approval"
                                    ? "default"
                                    : "pointer",
                              }}
                            >
                              <p className="px-2 text-xs">
                                {"₹ " +
                                  Number(txnAmountFilter)?.toLocaleString(
                                    "en-IN",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </p>
                              <div
                                className="int_txn_viewall_close_btn"
                                onClick={() => {
                                  handleFilterAmountMethod();
                                }}
                                style={{ cursor: "pointer" }}
                                disabled={
                                  currentStatus === "require approval"
                                    ? true
                                    : false
                                }
                              >
                                {" "}
                                <img
                                  src={closeBtnImg}
                                  alt="close-btn"
                                  className="w-[12px] h-[12px]"
                                />
                              </div>
                            </div>
                          )}
                          {isConfidentialMethod && (
                            <div
                              className="bene_txn_viewall_filter_body px-2"
                              style={{
                                opacity:
                                  currentStatus === "require approval"
                                    ? "0.5"
                                    : "1",
                                cursor:
                                  currentStatus === "require approval"
                                    ? "default"
                                    : "pointer",
                              }}
                            >
                              <p className="px-2 text-xs">
                                {isConfidentialMethod}
                              </p>
                              <div
                                className="int_txn_viewall_close_btn"
                                onClick={() => {
                                  if (
                                    props?.popup !== "Confidential Transaction"
                                  ) {
                                    handleFilterConfidentialSticker();
                                  }
                                }}
                                style={{
                                  cursor: "pointer",
                                  opacity:
                                    props?.popup === "Confidential Transaction"
                                      ? "0.4"
                                      : "1",
                                }}
                                disabled={
                                  currentStatus === "require approval"
                                    ? true
                                    : false
                                }
                              >
                                {" "}
                                <img
                                  src={closeBtnImg}
                                  alt="close-btn"
                                  className="w-[12px] h-[12px]"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div
                className="beneTxnViewAll_middle_section_container"
                style={{
                  marginTop:
                    isMobile && props?.OwnerDetail && !props?.internalAccount
                      ? isFilterApplied
                        ? "17.8rem"
                        : "12rem"
                      : marginTop,
                  marginBottom: "0",
                }}
              ></div>
              <div
                ref={scrollContainerRef}
                id="txnViewallScrollContainer"
                className={`${
                  props?.txnHistorytype === "internalTxnHistory" &&
                  hideCarousal &&
                  "p-5"
                }`}
                style={{
                  opacity:
                    isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                      ? "0.8"
                      : "1",
                  backgroundColor:
                    isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                      ? "var(--Primary-black)"
                      : "",
                  overflowY:
                    isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                      ? "hidden"
                      : "auto",
                  height:
                    isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                      ? "100vh"
                      : "100%",
                  paddingBottom:
                    accumulatedData.length === 0 &&
                    props?.txnHistorytype !== "ownerDetailTxnHistory" &&
                    "60px",
                  marginTop:
                    currentCategory !== "" ||
                    startDateFilter ||
                    endDateFilter ||
                    isPaymentMethod ||
                    txnAmountFilter ||
                    selectedOwnerAccId ||
                    isConfidentialMethod ||
                    selectedBeneAccId
                      ? "40px"
                      : "15px",
                  marginBottom:
                    currentCategory !== "" ||
                    startDateFilter ||
                    endDateFilter ||
                    isPaymentMethod ||
                    txnAmountFilter ||
                    selectedOwnerAccId ||
                    selectedBeneAccId
                      ? ""
                      : "21px",
                }}
              >
                {loading ? (
                  <div className="loader-container m-auto justify-center items-center flex">
                    <Loader />
                  </div>
                ) : accumulatedData?.length > 0 ? (
                  <div
                    className="flex flex-col items-center p-1 w-full h-full"
                    style={{
                      opacity:
                        isSidebarOpen ||
                        isPopoverOpen ||
                        isDistributionPopoverOpen
                          ? "0.3"
                          : "1",
                      background:
                        isSidebarOpen ||
                        isPopoverOpen ||
                        isDistributionPopoverOpen
                          ? "var(--Primary_black)"
                          : "",
                      pointerEvents:
                        isSidebarOpen ||
                        isPopoverOpen ||
                        isDistributionPopoverOpen ||
                        isViewModalOpen ||
                        isViewModalOpenOwner
                          ? "none"
                          : "",
                    }}
                  >
                    <InternalTxnViewAllCard
                      data={accumulatedData}
                      lastElementRef={lastElementRef}
                      isSidebarOpen={isSidebarOpen}
                      numberOfPages={totalPages}
                      isCardHovered={isCardHovered}
                      setIsCardHovered={setIsCardHovered}
                      isPopoverOpen={isPopoverOpen}
                      currentStatus={currentStatus}
                      isDistributionPopoverOpen={isDistributionPopoverOpen}
                      txnHistorytype={props?.txnHistorytype}
                      onClose={props?.onClose}
                      isMobile={isMobile}
                    />
                  </div>
                ) : setTxnAmount && accumulatedData?.length === 0 ? (
                  <div
                    className=" internal-txn-noData-mob flex flex-col items-center justify-center m-auto"
                    style={{
                      opacity:
                        isSidebarOpen ||
                        isPopoverOpen ||
                        isDistributionPopoverOpen
                          ? 0.3
                          : 1,
                    }}
                  >
                    <img
                      src={noaccount}
                      alt="No accounts found"
                      className="bene-txn-no-acc-image-mob"
                    />
                    <p
                      className="text-Font_16 text-Primary_white text-center"
                      style={{ width: "314px" }}
                    >
                      {/* mob search */}
                      No data found.
                    </p>
                    {(isFilterApplied || txnAmount || txnAddDetails) && (
                      <button
                        onClick={(e) => {
                          setTxnAmount("");
                          // setLocalAddDetails("");
                          setTxnAddDetails("");
                          clearAllFilters();
                        }}
                        className="internal-txn-clear-params-btn"
                      >
                        Clear parameters
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            id="beneScrollContainer"
            className={`bg-custom-appBack flex flex-col h-[96vh] ${
              props?.txnHistorytype === "internalTxnHistory"
                ? "h-full"
                : "h-screen"
            }`}
          >
            {isPopoverOpen && (
              <div className="acc_blackTxn_div bg-custom-backdrop"></div>
            )}
            {isSidebarOpen && (
              <div className="acc_blackTxn_div bg-custom-backdrop"></div>
            )}
            {isDistributionPopoverOpen && (
              <div className="acc_blackTxn_div bg-custom-backdrop"></div>
            )}

            {
              <div
                style={{
                  zIndex:
                    isSidebarOpen || isDistributionPopoverOpen || isPopoverOpen
                      ? "9"
                      : "9999",
                  width:
                    props?.txnHistorytype === "internalTxnHistory" ||
                    props?.txnHistorytype === "ownerDetailTxnHistory"
                      ? "96%"
                      : "100%",
                  bottom:
                    props?.txnHistorytype === "internalTxnHistory" ||
                    props?.txnHistorytype === "ownerDetailTxnHistory"
                      ? "4%"
                      : "0",
                  borderRadius:
                    props?.txnHistorytype === "internalTxnHistory" ||
                    props?.txnHistorytype === "ownerDetailTxnHistory"
                      ? "0px 0px 20px 20px"
                      : "0",
                }}
                className="fixed w-full bg-Blue_4 h-[50px]"
              >
                <PaginationComponent
                  handleSelect={handleSelect}
                  selectedValue={selectedValue}
                  data={mainData}
                  pageSize={pageSize}
                  dropdownOptions={dropdownOptions}
                  handlePagination={handlePagination}
                  currentPage={currentPage}
                />
              </div>
            }
            {isViewModalOpen && (
              <div
                style={{ zIndex: "2147483647" }}
                className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80"
              >
                <BeneTxnViewModal
                  setIsSidebarOpen={setIsSidebarOpen}
                  onClosed={handleCloseViewModal}
                  onAccountSelect={handleAccountSelection}
                  setTxnPayLoad={setTxnPayLoad}
                  txnPayLoad={txnPayLoad}
                  accountNumber={accountNumber}
                  IFSC={IFSC}
                  bankLogo={bankLogo}
                />
              </div>
            )}

            {isViewModalOpenOwner && (
              <div
                style={{ zIndex: "2147483647" }}
                className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80"
              >
                <OwnerAccountPopUp
                  setIsSidebarOpen={setIsSidebarOpen}
                  onClosed={handleCloseViewModalOwner}
                  onAccountSelect={handleAccountSelectionOwner}
                  setTxnPayLoad={setTxnPayLoad}
                  setOpenOwner={setIsViewModalOpenOwner}
                  txnPayLoad={txnPayLoad}
                  accountNumber={accountNumber}
                  IFSC={IFSC}
                  bankLogo={bankLogo}
                  flow={"txnViewAll"}
                  handlePopupClose={() => {
                    setIsViewModalOpenOwner(false);
                  }}
                />
              </div>
            )}

            <div
              className={`bg-custom-appBack flex flex-col ${
                props?.txnHistorytype === "internalTxnHistory" ||
                props?.txnHistorytype === "ownerDetailTxnHistory" ||
                props?.internalAccount
                  ? "h-full"
                  : "h-screen"
              }`}
              id=" benetxnmain "
              style={{
                overflow: isSidebarOpen && "hidden",
                // opacity: isViewModalOpen || isViewModalOpenOwner ? 0.2 : 1,
              }}
            >
              <div
                style={{
                  display: props?.internalAccount
                    ? "flex"
                    : props?.txnHistorytype === "internalTxnHistory" &&
                      !props?.OwnerDetail
                    ? "flex"
                    : "none",
                }}
                className={`transactionHistoryPop_topDiv ${
                  props?.txnHistorytype === "internalTxnHistory" ||
                  props?.internalAccount
                    ? "flex flex-row justify-between items-center p-5"
                    : "hidden"
                }`}
              >
                <div className="flex flex-col space-y-1">
                  {props?.txnHistorytype === "internalTxnHistory" ? (
                    <p className="text-base text-Primary_white font-sans font-semibold">
                      {props?.popup}
                    </p>
                  ) : (
                    <p className="text-base text-Primary_white font-sans font-semibold">
                      Transaction History
                    </p>
                  )}
                  <p className="text-xs text-Primary_grey font-sans font-normal">
                    {currentText}
                  </p>
                </div>
                <div
                  className="flex justify-center items-center cursor-pointer w-7 h-7 rounded-[5px] bg-Blue_5 hover:bg-Blue_5_hover"
                  onClick={() => props?.onClose()}
                >
                  <img src={closeIcon} className="w-4 h-4" />
                </div>
              </div>
              <div className="internalTxnViewAllNavbar">
                <div className="internalTxnViewAllContent"></div>
                <div
                  className="internalTxnViewAllSec1"
                  // ref={searchBarRef}
                  style={{
                    height:
                      props?.txnHistorytype === "internalTxnHistory" ||
                      props?.txnHistorytype === "ownerDetailTxnHistory"
                        ? "160px"
                        : showSearchBar
                        ? "237px"
                        : "175px",
                    transition: showSearchBar
                      ? "all 0.2s ease-in"
                      : "all 0.2s ease-in",
                    width:
                      props?.txnHistorytype === "internalTxnHistory" ||
                      props?.txnHistorytype === "ownerDetailTxnHistory"
                        ? "96%"
                        : "100%",
                    borderRadius:
                      props?.txnHistorytype === "internalTxnHistory" ||
                      props?.txnHistorytype === "ownerDetailTxnHistory"
                        ? "20px 20px 0px 0px"
                        : "0px",
                  }}
                >
                  <div
                    className="internalTxnViewAllSec2"
                    style={{
                      width:
                        props?.txnHistorytype === "internalTxnHistory" ||
                        props?.txnHistorytype === "ownerDetailTxnHistory"
                          ? "100%"
                          : "1024px",
                      background:
                        props?.txnHistorytype === "internalTxnHistory" ||
                        props?.txnHistorytype === "ownerDetailTxnHistory"
                          ? null
                          : "linear-gradient(to right, var(--Blue_7) 32.5%, var(--Blue_6) 20%)",
                    }}
                  >
                    <div
                      className="internalTxnViewAll_middleBar"
                      style={{
                        height:
                          props?.txnHistorytype === "internalTxnHistory" ||
                          props?.txnHistorytype === "ownerDetailTxnHistory"
                            ? "28px"
                            : "59px",
                      }}
                    >
                      <div
                        className="internalTxnViewAllWholeSection"
                        // style={{
                        // }}
                      >
                        <div className="internalTxnViewAllParentdiv">
                          <div className="internalTxnViewAllchild1">
                            <BeneTxnStatusPopover
                              typepop={props?.typepop}
                              data={accumulatedData}
                              getAccStatus={getAccStatus}
                              DashBoardStatusData={DashBoardStatus}
                              DistributionStatusData={DistributionDashBoard}
                              SetDashBoardStatusData={SetDashBoardStatus}
                              selectedStatus={currentStatus}
                              setPage={setCurrentPage}
                              setIsApprovalStatusSelected={
                                setIsApprovalStatusSelected
                              }
                              isDistributionSelected={isDistributionSelected}
                              setIsDistributionSelected={
                                setIsDistributionSelected
                              }
                              isStatusSelected={isStatusSelected}
                              setIsStatusSelected={setIsStatusSelected}
                              setIsPopoverOpen={setIsPopoverOpen}
                              isPopoverOpen={isPopoverOpen}
                              currentCategory={currentCategory}
                              setCurrentCategory={setCurrentCategory}
                              setPaymentMethod={setPaymentMethod}
                              setTempEndDate={setTempEndDate}
                              setTempStartDate={setTempStartDate}
                              setSelectedOwnerAccId={setSelectedOwnerAccId}
                              setTxnAmountFilter={setTxnAmountFilter}
                              setSelectedBeneAccId={setSelectedBeneAccId}
                              sendPaymentMethod={handlePaymentMethod}
                              setStartDateFilter={setStartDateFilter}
                              setEndDateFilter={setEndDateFilter}
                              setInitCategory={setInitCategory}
                              setTxnPayLoad={setTxnPayLoad}
                              txnPayLoad={txnPayLoad}
                              setBeneAccountId={setBeneAccountId}
                              setEndDate={setEndDate}
                              setStartDate={setStartDate}
                              setAccountName={setAccountName}
                              isSidebarOpen={isSidebarOpen}
                              showSearchBar={showSearchBar}
                              txnHistorytype={props?.txnHistorytype}
                              setConfidentialMethod={setConfidentialMethod}
                              sendConfidentialMethod={handleConfidentialMethod}
                              isFilterApplied={isFilterApplied}
                            />
                          </div>
                        </div>

                        <div className="ver__divider"></div>

                        <div
                          className="internalTxnViewallDistriSection"
                          style={{ height: "59px", marginTop: "0" }}
                        >
                          <div className="internalTxnViewallDistri">
                            <DistributionStatusPopover
                              typepop={props?.typepop}
                              setAccountName={setAccountName}
                              setBeneAccountId={setBeneAccountId}
                              setSelectedBeneAccId={setSelectedBeneAccId}
                              setConfidentialMethod={setConfidentialMethod}
                              data={accumulatedData}
                              getAccStatus={getAccStatus}
                              selectedStatus={currentStatusDistribution}
                              setPage={setCurrentPage}
                              isDistributionSelected={isDistributionSelected}
                              setIsDistributionSelected={
                                setIsDistributionSelected
                              }
                              isStatusSelected={isStatusSelected}
                              setIsStatusSelected={setIsStatusSelected}
                              setIsDistributionPopoverOpen={
                                setIsDistributionPopoverOpen
                              }
                              isDistributionPopoverOpen={
                                isDistributionPopoverOpen
                              }
                              DashBoardStatusData={DashBoardStatus}
                              DistributionStatusData={DistributionDashBoard}
                              SetDashBoardStatusData={SetDashBoardStatus}
                              isSidebarOpen={isSidebarOpen}
                              showSearchBar={showSearchBar}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="totalBalance">
                        <div className="totalBalanceAmountDiv">
                          ₹{" "}
                          {totalBalanceAmount !== undefined
                            ? formatTotalBalance(totalBalanceAmount).amount
                            : "00.00"}
                        </div>
                        <p
                          style={{
                            width: "250px",
                            height: "17px",
                            font: "normal normal normal 12px/17px Open Sans",
                            color: "var(--Primary_grey)",
                          }}
                        >
                          Total amount{" "}
                          {formatTotalBalance(totalBalanceAmount).prefix == ""
                            ? ""
                            : "- in " +
                              formatTotalBalance(totalBalanceAmount).prefix}
                        </p>
                      </div>
                      <div className="secondDivElement">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                zIndex: 99999,
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title="Search"
                        >
                          <div
                            ref={searchBarRef}
                            className={`internalTxn_viewAll_search_div ${
                              txnAddDetails !== "" ? "searched" : ""
                            }  ${isSidebarOpen ? "" : "hover:bg-Blue_4_hover"}`}
                            style={{
                              opacity: isSidebarOpen ? "0.6" : "1",
                              cursor: isSidebarOpen ? "default" : "pointer",
                            }}
                            onClick={() => {
                              if (!isSidebarOpen) {
                                setShowSearchBar((prev) => !prev);
                              } else if (isSidebarOpen || showSearchBar) {
                                setIsSidebarOpen(false);
                              }
                            }}
                          >
                            <img
                              className="internalTxn_search-image"
                              src={txnAddDetails ? search_blue : search_white}
                              alt=""
                            />
                            <img
                              src={getIcon()}
                              alt=""
                              className="w-[12px] h-[12px]"
                            />
                          </div>
                        </Tooltip>
                        {showSearchBar && (
                          <>
                            <TxnSearchBar
                              handleSearch={handleSearch}
                              showSearchBar={showSearchBar}
                              setShowSearchBar={setShowSearchBar}
                              setCurrentPage={setCurrentPage}
                              // setSearchTxnAmount={setSearchTxnAmount}
                              txnAmount={txnAmount}
                              txnAddDetails={txnAddDetails}
                              setTxnAddDetails={setTxnAddDetails}
                              setTxnAmount={setTxnAmount}
                              txnSearchType={txnSearchType}
                              setTxnSearchType={setTxnSearchType}
                              handlePaste={handlePaste}
                              toggleShowSearchBar={() => {
                                setShowSearchBar(!showSearchBar);
                              }}
                              bgColor={"bg-custom-card"}
                              bgHover={"hover:bg-custom-cardHover"}
                              transactionHistorypopup={
                                props?.popup || props?.OwnerDetail
                              }
                              flow={"internal"}
                              searchBarInputRef={searchBarInputRef}
                            />
                          </>
                        )}
                        <div
                          ref={filterRef}
                          className={`internalTxn_viewall_filter_div ${
                            currentCategory !== "" ||
                            (startDateFilter && endDateFilter) ||
                            isPaymentMethod ||
                            selectedOwnerAccId ||
                            isConfidentialMethod ||
                            txnAmountFilter ||
                            selectedBeneAccId
                              ? "filtered"
                              : ""
                          }`}
                        >
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  zIndex: 99999,
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title="Filters"
                            disableFocusListener={true}
                            disableHoverListener={true}
                            disableTouchListener={true}
                          >
                            <div
                              ref={sidebarButtonRef}
                              style={{
                                pointerEvents:
                                  isViewModalOpen || isViewModalOpenOwner
                                    ? "none"
                                    : "",
                                opacity:
                                  showSearchBar ||
                                  currentStatus === "require approval"
                                    ? "0.6"
                                    : "1",
                                cursor:
                                  showSearchBar ||
                                  currentStatus === "require approval"
                                    ? "default"
                                    : "pointer",
                              }}
                              className={`internalTxnViewAll_filter_container  ${
                                showSearchBar ? "" : "hover:bg-Blue_4_hover"
                              }`}
                              onClick={(e) => {
                                if (
                                  !showSearchBar &&
                                  currentStatus !== "require approval"
                                ) {
                                  // if (e && e.stopPropagation) {
                                  //   e.stopPropagation();
                                  // }
                                  toggleSidebar();
                                } else if (showSearchBar) {
                                  setShowSearchBar(false);
                                } else if (
                                  currentStatus === "require approval"
                                ) {
                                  toast.warn(
                                    "Filter restricted while status is require approval.",
                                    toast_position
                                  );
                                }
                              }}
                            >
                              <Box className="internalTxnViewAll_filter_box">
                                <img
                                  src={isFilterApplied ? FilterBlue : Filter}
                                  alt=""
                                  className="internal-txn-filter-image"
                                />
                              </Box>
                              <Box className="internalTxnViewAll_filter_box">
                                {" "}
                                <img
                                  src={getArrowIcon(
                                    isSidebarOpen,
                                    isFilterApplied
                                  )}
                                  alt=""
                                  className="filter-images-ele"
                                />
                              </Box>
                            </div>
                          </Tooltip>
                          <InternalTxnFilterSidebar
                            setClearAllFilters={setClearAllFilters}
                            txnHistorytype={props?.txnHistorytype}
                            detailConfidential={
                              props?.internal_account_confidential
                            }
                            isOpen={isSidebarOpen}
                            onClose={toggleSidebar}
                            sendCategory={handleFilterCategory}
                            handleFilterDateRange={handleFilterDateRange}
                            setPage={setCurrentPage}
                            setIsSidebarOpen={setIsSidebarOpen}
                            initCategory={initCategory}
                            setInitCategory={setInitCategory}
                            isPaymentMethod={isPaymentMethod}
                            setEndDateFilter={setEndDateFilter}
                            setStartDateFilter={setStartDateFilter}
                            sendPaymentMethod={handlePaymentMethod}
                            setTxnPayLoad={setTxnPayLoad}
                            startDateFilter={startDateFilter}
                            endDateFilter={endDateFilter}
                            handleAmountSelected={handleAmountSelected}
                            txnPayLoad={txnPayLoad}
                            isSidebarOpen={isSidebarOpen}
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                            selectedBeneAccId={selectedBeneAccId}
                            setSelectedBeneAccId={setSelectedBeneAccId}
                            beneAccountId={beneAccountId}
                            setBeneAccountId={setBeneAccountId}
                            accountName={accountName}
                            setAccountName={setAccountName}
                            showDatePicker={showDatePicker}
                            setShowDatePicker={setShowDatePicker}
                            isFilterApplied={isFilterApplied}
                            setIsFilterApplied={setIsFilterApplied}
                            settlementDropdownRef={settlementDropdownRef}
                            paymentDropdownRef={paymentDropdownRef}
                            categoryDropdownRef={categoryDropdownRef}
                            dateRef={dateRef}
                            dropdownOpen={dropdownOpen}
                            setIsDropdownOpen={setIsDropdownOpen}
                            isDropdownOpen={isDropdownOpen}
                            setDropdownOpen={setDropdownOpen}
                            isDropdownPaymentOpen={isDropdownPaymentOpen}
                            setDropdownPaymentOpen={setDropdownPaymentOpen}
                            setTxnAmountFilter={setTxnAmountFilter}
                            txnAmountFilter={txnAmountFilter}
                            onClosed={handleCloseViewModal}
                            onAccountSelect={handleAccountSelection}
                            isViewModalOpen={isViewModalOpen}
                            handleOpenViewModal={handleOpenViewModal}
                            viewModalRef={viewModalRef}
                            handleOpenViewModalOwner={handleOpenViewModalOwner}
                            selectedOwnerAccId={selectedOwnerAccId}
                            setSelectedOwnerAccId={setSelectedOwnerAccId}
                            accountNumber={accountNumber}
                            setAccountNumber={setAccountNumber}
                            tempStartDate={tempStartDate}
                            tempEndDate={tempEndDate}
                            setTempEndDate={setTempEndDate}
                            setTempStartDate={setTempStartDate}
                            currentStatus={currentStatus}
                            setCurrentStatus={setCurrentStatus}
                            balanceType={props?.balanceType}
                            internalAccount={props?.internalAccount}
                            // filterRef={filterRef}
                            isConfidentialMethod={isConfidentialMethod}
                            sendConfidentialMethod={handleConfidentialMethod}
                            confidentialMethod={confidentialMethod}
                            setConfidentialMethod={setConfidentialMethod}
                            confidentialDropdownRef={confidentialDropdownRef}
                            isDropdownConfidentialOpen={
                              isDropdownConfidentialOpen
                            }
                            setIsDropdownConfidentialOpen={
                              setIsDropdownConfidentialOpen
                            }
                            popup={props.popup}
                            isConfidentialFilter={isConfidentialFilter}
                            DashBoardStatus={DashBoardStatus}
                            DashBoardisConfidentialFilter={
                              DashBoardisConfidentialFilter
                            }
                            SetDashBoardisConfidentialFilter={
                              SetDashBoardisConfidentialFilter
                            }
                            isDashboradCheck={isDashboradCheck}
                          />
                        </div>

                        <div
                          className="internalTxn_viewall_toggle_containers"
                          style={{
                            opacity:
                              isSidebarOpen || showSearchBar ? "0.6" : "1",
                            cursor:
                              isSidebarOpen || showSearchBar
                                ? "default"
                                : "pointer",
                          }}
                        >
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  zIndex: 99999,
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title="Grid"
                          >
                            <Box
                              onClick={() => {
                                // setSearchUserText("");
                                // dispatch(setToggle(false));
                                // setCurrentPage(1);
                                // setCurrentPageText("01");
                                handleToggle(false);
                              }}
                              sx={{
                                backgroundColor: toggle
                                  ? ""
                                  : "var(--Primary_white)",
                              }}
                              className={`${
                                toggle && !showSearchBar && !isSidebarOpen
                                  ? "hover:bg-Blue_4_hover"
                                  : ""
                              } toggle-box-txn`}
                            >
                              <img
                                src={toggle ? GridWhite : GridBlue}
                                alt=""
                                className="toggle-images"
                              />
                            </Box>
                          </Tooltip>
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  zIndex: 99999,
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title="List"
                          >
                            <Box
                              onClick={() => {
                                handleToggle(true);
                                // setSearchUserText("");
                                // dispatch(setToggle(true));

                                // setCurrentPage(1);
                              }}
                              sx={{
                                backgroundColor: toggle
                                  ? "var(--Primary_white)"
                                  : "",
                              }}
                              className={`${
                                !toggle && !showSearchBar && !isSidebarOpen
                                  ? "hover:bg-Blue_7"
                                  : ""
                              } toggle-box-txn`}
                            >
                              <img
                                src={toggle ? ListBlue : ListWhite}
                                alt=""
                                className="toggle-images"
                              />
                            </Box>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {currentCategory !== "" ||
              isPaymentMethod ||
              txnAmountFilter ||
              selectedOwnerAccId ||
              isConfidentialMethod ||
              selectedBeneAccId ||
              (startDateFilter &&
                endDateFilter &&
                startDateFilter > 0 &&
                endDateFilter > 0 &&
                !isNaN(Number(startDateFilter)) &&
                !isNaN(Number(endDateFilter))) ? (
                <div
                  className="internalTxn_filterdropdown-container-div"
                  style={{
                    // height:
                    //   (props?.txnHistorytype === "internalTxnHistory" ||
                    //     props?.txnHistorytype === "ownerDetailTxnHistory") &&
                    //   hideCarousal
                    //     ? "116px"
                    //     : props?.txnHistorytype === "internalTxnHistory" ||
                    //       props?.txnHistorytype === "ownerDetailTxnHistory"
                    //     ? "109px"
                    //     : hideCarousal
                    //     ? "auto"
                    //     : "122px",
                    width:
                      props?.txnHistorytype === "internalTxnHistory" ||
                      props?.txnHistorytype === "ownerDetailTxnHistory"
                        ? "96%"
                        : null,
                    left:
                      props?.txnHistorytype === "internalTxnHistory" ||
                      props?.txnHistorytype === "ownerDetailTxnHistory"
                        ? "2%"
                        : null,
                    top:
                      props?.txnHistorytype === "internalTxnHistory" ||
                      props?.txnHistorytype === "ownerDetailTxnHistory"
                        ? "4%"
                        : null,
                  }}
                >
                  <div className="internalTxn_filterdropdown">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        gap: "21px",
                        marginTop:
                          props?.txnHistorytype === "internalTxnHistory" ||
                          props?.txnHistorytype === "ownerDetailTxnHistory"
                            ? ""
                            : // : showSearchBar && (!props?.popup && !props?.OwnerDetail)
                            // ? "78px"
                            showSearchBar &&
                              (props?.popup || props?.OwnerDetail)
                            ? "98px"
                            : showSearchBar
                            ? "20px"
                            : "15px",
                        paddingTop:
                          showSearchBar &&
                          (props?.popup !== "" || props?.OwnerDetail)
                            ? "69px"
                            : "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          borderTop: "1px solid rgb(29, 58, 109)",
                        }}
                      >
                        <p
                          className="pr-2 w-[80px]"
                          style={{
                            whiteSpace: "nowrap",
                            flexShrink: 0,
                            color: " var(--Primary_white)",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          Filtered by :
                        </p>
                        <div
                          className="internalTxn_viewall_filter_div_left"
                          style={{
                            gap: "10px",
                            display: "flex",
                            maxWidth: "1024",
                            overflowX: "hidden",
                            whiteSpace: "nowrap",
                          }}
                          ref={scrollRef}
                        >
                          {selectedOwnerAccId && (
                            <div
                              className="internalTxn_viewall_filter_body px-2 mr-[5px]"
                              style={{
                                opacity:
                                  currentStatus === "require approval"
                                    ? "0.5"
                                    : "1",
                                cursor:
                                  currentStatus === "require approval" ||
                                  props?.txnHistorytype === "internalTxnHistory"
                                    ? "default"
                                    : "pointer",
                              }}
                            >
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      zIndex: 9999999,
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={formatAccountNumber(
                                  props?.OwnerDetail &&
                                    props?.balanceType === "credit"
                                    ? txnPayLoad?.ownerbankAccountNo
                                    : accountNumber
                                )}
                              >
                                <p
                                  className="px-2 text-xs"
                                  style={{
                                    flex: "1",
                                    width: "auto",
                                    color: "var(--Primary_white)",
                                    letterSpacing: " 0px",
                                    textTransform: "capitalize",
                                    opacity: "1",
                                  }}
                                >
                                  {formatAccountNumber(
                                    props?.OwnerDetail &&
                                      props?.balanceType === "credit"
                                      ? txnPayLoad?.ownerbankAccountNo
                                      : accountNumber
                                  )}
                                </p>
                              </Tooltip>
                              <div
                                className="internalTxn_viewall_close_btn"
                                onClick={() => {
                                  if (
                                    props?.txnHistorytype !==
                                      "internalTxnHistory" &&
                                    props?.txnHistorytype !==
                                      "ownerDetailTxnHistory"
                                  ) {
                                    handleFilterPaymentStickerOwner();
                                  } else if (
                                    props?.txnHistorytype ===
                                      "ownerDetailTxnHistory" &&
                                    props?.balanceType === "credit"
                                  ) {
                                    handleFilterPaymentStickerOwner();
                                  } else {
                                    handleFilterPaymentStickerOwner();
                                  }
                                }}
                                style={{
                                  cursor:
                                    props?.txnHistorytype ===
                                      "internalTxnHistory" ||
                                    (props?.txnHistorytype ===
                                      "ownerDetailTxnHistory" &&
                                      props?.balanceType === "debit")
                                      ? "default"
                                      : "pointer",
                                  opacity:
                                    props?.txnHistorytype ===
                                      "internalTxnHistory" ||
                                    (props?.txnHistorytype ===
                                      "ownerDetailTxnHistory" &&
                                      props?.balanceType === "debit")
                                      ? "0.4"
                                      : "1",
                                }}
                                disabled={
                                  currentStatus === "require approval"
                                    ? true
                                    : false
                                }
                              >
                                {" "}
                                <img
                                  src={closeBtnImg}
                                  alt="close-btn"
                                  className="w-[12px] h-[12px]"
                                />
                              </div>
                            </div>
                          )}
                          {selectedBeneAccId && (
                            <div
                              className="internalTxn_viewall_filter_body px-2 mr-[5px]"
                              style={{
                                opacity:
                                  currentStatus === "require approval"
                                    ? "0.5"
                                    : "1",
                                cursor:
                                  currentStatus === "require approval" ||
                                  props?.txnHistorytype ===
                                    "ownerDetailTxnHistory"
                                    ? "default"
                                    : "pointer",
                              }}
                            >
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      zIndex: 9999999,
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={formatAccountNumber(
                                  props?.OwnerDetail
                                    ? props?.owner_account_bank_acc_no
                                    : accountName?.toLowerCase()
                                )}
                              >
                                <p
                                  className="px-2 text-xs"
                                  style={{
                                    flex: "1",
                                    width: "auto",
                                    color: "var(--Primary_white)",
                                    letterSpacing: " 0px",
                                    textTransform: "capitalize",
                                    opacity: "1",
                                  }}
                                >
                                  {formatAccountNumber(
                                    props?.OwnerDetail
                                      ? props?.owner_account_bank_acc_no
                                      : accountName?.toLowerCase()
                                  )}
                                </p>
                              </Tooltip>
                              <div
                                className="internalTxn_viewall_close_btn"
                                onClick={() => {
                                  if (
                                    props?.txnHistorytype !==
                                    "internalTxnHistory"
                                  ) {
                                    return;
                                  } else {
                                    handleFilterBeneAccSticker();
                                  }
                                }}
                                style={{
                                  cursor:
                                    props?.txnHistorytype ===
                                      "internalTxnHistory" ||
                                    props?.txnHistorytype ===
                                      "ownerDetailTxnHistory"
                                      ? "default"
                                      : "pointer",
                                  opacity:
                                    props?.txnHistorytype ===
                                      "internalTxnHistory" ||
                                    props?.txnHistorytype ===
                                      "ownerDetailTxnHistory"
                                      ? "0.4"
                                      : "1",
                                }}
                                disabled={
                                  currentStatus === "require approval"
                                    ? true
                                    : false
                                }
                              >
                                {" "}
                                <img
                                  src={closeBtnImg}
                                  alt="close-btn"
                                  className="w-[12px] h-[12px]"
                                />
                              </div>
                            </div>
                          )}
                          {currentCategory && (
                            <>
                              <div className="internalTxn_viewall_filter_body px-2 mr-[5px]">
                                <div className="internalTxn_viewall_filter_img_div">
                                  <img src={rentalImg} alt={currentCategory} />
                                </div>
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        zIndex: 9999,
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={currentCategory}
                                >
                                  <p
                                    className="px-2 text-xs truncate"
                                    style={{
                                      flex: "1",
                                      width: "112px",
                                      color: "var(--Primary_white)",
                                      letterSpacing: " 0px",
                                      textTransform: "capitalize",
                                      opacity: "1",
                                    }}
                                  >
                                    {currentCategory}
                                  </p>
                                </Tooltip>
                                <div
                                  className="internalTxn_viewall_close_btn"
                                  onClick={handleFilterSticker}
                                  style={{ cursor: "pointer" }}
                                >
                                  {" "}
                                  <img
                                    src={closeBtnImg}
                                    alt="close-btn"
                                    className="w-[12px] h-[12px]"
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {startDateFilter > 0 &&
                            endDateFilter > 0 &&
                            !isNaN(Number(startDateFilter)) &&
                            !isNaN(Number(endDateFilter)) && (
                              <div
                                className="internalTxn_viewall_filter_body px-2 mr-[10px]"
                                style={{
                                  width: "231px !important",
                                  opacity:
                                    currentStatus === "require approval"
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    currentStatus === "require approval"
                                      ? "default"
                                      : "pointer",
                                }}
                              >
                                <div className="px-2 text-xs">
                                  {formatDate(
                                    new Date(Number(startDateFilter))
                                  )}
                                </div>

                                {"-"}
                                <div className="px-2 text-xs">
                                  {formatDate(new Date(Number(endDateFilter)))}
                                </div>
                                <div
                                  className="internalTxn_viewall_close_btn"
                                  onClick={handleFilterDateSticker}
                                  disabled={
                                    currentStatus === "require approval"
                                      ? true
                                      : false
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={closeBtnImg}
                                    alt="close-btn"
                                    className="w-[12px] h-[12px] cursor-pointer "
                                  />
                                </div>
                              </div>
                            )}

                          {isPaymentMethod && (
                            <div
                              className="internalTxn_viewall_filter_body px-2"
                              style={{
                                opacity:
                                  currentStatus === "require approval"
                                    ? "0.5"
                                    : "1",
                                cursor:
                                  currentStatus === "require approval"
                                    ? "default"
                                    : "pointer",
                              }}
                            >
                              <p className="px-2 text-xs">{isPaymentMethod}</p>
                              <div
                                className="internalTxn_viewall_close_btn"
                                onClick={() => {
                                  handleFilterPaymentSticker();
                                }}
                                disabled={
                                  currentStatus === "require approval"
                                    ? true
                                    : false
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {" "}
                                <img
                                  src={closeBtnImg}
                                  alt="close-btn"
                                  className="w-[12px] h-[12px]"
                                />
                              </div>
                            </div>
                          )}

                          {txnAmountFilter && (
                            <div
                              className="internalTxn_viewall_filter_body px-2"
                              style={{
                                opacity:
                                  currentStatus === "require approval"
                                    ? "0.5"
                                    : "1",
                                cursor:
                                  currentStatus === "require approval"
                                    ? "default"
                                    : "pointer",
                              }}
                            >
                              <p className="px-2 text-xs">
                                {"₹ " +
                                  Number(txnAmountFilter)?.toLocaleString(
                                    "en-IN",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </p>
                              <div
                                className="internalTxn_viewall_close_btn"
                                onClick={() => {
                                  handleFilterAmountMethod();
                                }}
                                disabled={
                                  currentStatus === "require approval"
                                    ? true
                                    : false
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {" "}
                                <img
                                  src={closeBtnImg}
                                  alt="close-btn"
                                  className="w-[12px] h-[12px]"
                                />
                              </div>
                            </div>
                          )}
                          {isConfidentialMethod && (
                            <div
                              className="internalTxn_viewall_filter_body px-2"
                              style={{
                                opacity:
                                  currentStatus === "require approval"
                                    ? "0.5"
                                    : "1",
                                cursor:
                                  currentStatus === "require approval"
                                    ? "default"
                                    : "pointer",
                              }}
                            >
                              <p className="px-2 text-xs">
                                {isConfidentialMethod}
                              </p>
                              <div
                                className="internalTxn_viewall_close_btn"
                                onClick={() => {
                                  if (
                                    (props?.txnHistorytype ??
                                      "Transaction History") !==
                                      "internalTxnHistory" ||
                                    (props?.OwnerDetail ?? true)
                                  ) {
                                    !isConfPopup &&
                                      handleFilterConfidentialSticker();
                                  }
                                }}
                                style={{
                                  cursor: props?.OwnerDetail
                                    ? "pointer"
                                    : props?.txnHistorytype ===
                                        "internalTxnHistory" && isConfPopup
                                    ? "default"
                                    : "pointer",
                                  opacity: props?.OwnerDetail
                                    ? "1"
                                    : props?.txnHistorytype ===
                                        "internalTxnHistory" && isConfPopup
                                    ? "0.4"
                                    : "1",
                                }}
                                disabled={
                                  currentStatus === "require approval"
                                    ? true
                                    : false
                                }
                              >
                                {" "}
                                <img
                                  src={closeBtnImg}
                                  alt="close-btn"
                                  className="w-[12px] h-[12px]"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* <div
                    className="BeneTxn_CaraouselBar"
                    style={{
                      margin: "0 auto",
                      top: "-10px",
                      visibility: hideCarousal ? "visible" : "hidden",
                    }}
                  >
                    <div className="BeneTxn_CarouselDotbar">
                      <div
                        className="BeneTxn_FirstCaraoDot"
                        style={{
                          width: showSecondContent ? "12px" : "46px",
                          opacity: showSecondContent ? "0.65" : "1",
                          transition: "width 0.3s ease, opacity 0.3s ease",
                        }}
                      ></div>
                      <div
                        className="BeneTxn_SecondCaraoDot"
                        style={{
                          width: showSecondContent ? "46px" : "12px",
                          opacity: showSecondContent ? "1" : "0.65",
                          transition: "width 0.3s ease, opacity 0.3s ease",
                        }}
                      ></div>
                    </div>
                    <div className="BeneTxn_NextAndPrevIcon">
                      <div
                        className="BeneTxn_CarouPrevIcon"
                        onClick={disablePrev ? null : beneTxnPrev}
                      >
                        <img
                          src={PrevIcon}
                          alt=""
                          style={{
                            opacity: showSecondContent ? "1" : "0.5",
                            transition: "opacity 0.3s ease",
                          }}
                        />
                      </div>
                      <div
                        className="BeneTxn_CarouNextIcon"
                        onClick={disableNext ? null : beneTxnNext}
                      >
                        <img
                          src={NextIcon}
                          alt=""
                          style={{
                            opacity: showSecondContent ? "0.5" : "1",
                            transition: "opacity 0.3s ease",
                          }}
                        />
                      </div>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div
                className="internalTxnViewAll_middle_section_container"
                style={{
                  marginBottom:
                    props?.txnHistorytype === "ownerDetailTxnHistory" ||
                    (showSearchBar && !isFilterApplied)
                      ? "0"
                      : "15px",
                  marginTop,
                }}
              ></div>
              <div
                ref={scrollContainerRef}
                id="txnViewallScrollContainer"
                style={{
                  opacity:
                    isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                      ? "0.8"
                      : "1",
                  backgroundColor:
                    isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                      ? "var(--Primary_black)"
                      : "",
                  overflowY:
                    isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                      ? "hidden"
                      : "auto",
                  height:
                    props?.txnHistorytype === "ownerDetailTxnHistory" ||
                    props?.txnHistorytype === "internalTxnHistory"
                      ? toggle
                        ? "73%"
                        : "75%"
                      : isSidebarOpen ||
                        isPopoverOpen ||
                        isDistributionPopoverOpen
                      ? "100vh"
                      : "100%",
                  paddingBottom:
                    accumulatedData.length === 0 &&
                    props?.txnHistorytype !== "ownerDetailTxnHistory" &&
                    "60px",
                  marginTop:
                    props?.txnHistorytype === "internalTxnHistory"
                      ? "7px"
                      : isFilterApplied && showSearchBar
                      ? "27px"
                      : showSearchBar
                      ? "4px"
                      : isFilterApplied
                      ? "23px"
                      : showSearchBar || isFilterApplied
                      ? "22px"
                      : currentCategory !== "" ||
                        startDateFilter ||
                        endDateFilter ||
                        isPaymentMethod ||
                        txnAmountFilter ||
                        selectedOwnerAccId ||
                        isConfidentialMethod ||
                        selectedBeneAccId
                      ? "14px"
                      : "12px",
                }}
              >
                {!toggle ? (
                  loading ? (
                    <div className="loader-container m-auto justify-center items-center flex">
                      <Loader />
                    </div>
                  ) : accumulatedData?.length > 0 ? (
                    <div
                      className="flex flex-col items-center p-1 w-full h-full"
                      style={{
                        opacity:
                          isSidebarOpen ||
                          isPopoverOpen ||
                          isDistributionPopoverOpen
                            ? "0.3"
                            : "1",
                        background:
                          isSidebarOpen ||
                          isPopoverOpen ||
                          isDistributionPopoverOpen
                            ? "var(--Primary_black)"
                            : "",
                        pointerEvents:
                          isSidebarOpen ||
                          isPopoverOpen ||
                          isDistributionPopoverOpen ||
                          isViewModalOpen ||
                          isViewModalOpenOwner
                            ? "none"
                            : "",
                      }}
                    >
                      <InternalTxnViewAllCard
                        data={accumulatedData}
                        lastElementRef={lastElementRef}
                        isSidebarOpen={isSidebarOpen}
                        numberOfPages={totalPages}
                        isCardHovered={isCardHovered}
                        setIsCardHovered={setIsCardHovered}
                        isPopoverOpen={isPopoverOpen}
                        currentStatus={currentStatus}
                        isDistributionPopoverOpen={isDistributionPopoverOpen}
                        txnHistorytype={props?.txnHistorytype}
                        onClose={props?.onClose}
                      />
                    </div>
                  ) : setTxnAmount && accumulatedData?.length === 0 ? (
                    <div
                      className="internal-txn-noData flex flex-col items-center justify-center m-auto"
                      style={{
                        opacity:
                          isSidebarOpen ||
                          isPopoverOpen ||
                          isDistributionPopoverOpen
                            ? 0.3
                            : 1,
                        transform:
                          props?.txnHistorytype === "internalTxnHistory"
                            ? hasFilterBeenApplied
                              ? "translateY(-3rem)"
                              : "translateY(-3rem)"
                            : "",
                      }}
                    >
                      <img
                        style={{
                          marginTop:
                            props?.txnHistorytype === "ownerDetailTxnHistory"
                              ? "-100px"
                              : "0",
                          // width: "360px",
                          // height:
                          //   props?.txnHistorytype === "ownerDetailTxnHistory" ||
                          //   props?.txnHistorytype === "internalTxnHistory"
                          //     ? "250px"
                          //     : "250px",
                        }}
                        src={noaccount}
                        alt="No accounts found"
                        className="internal-txn-no-acc-image"
                      />
                      <p
                        className="text-Font_14 internal-txn-no-internalaccount-text"
                        style={{ width: "314px" }}
                      >
                        {/* web search  */}
                        No data found.
                      </p>
                      {(isFilterApplied || txnAmount || txnAddDetails) && (
                        <button
                          onClick={(e) => {
                            setTxnAmount("");
                            //  setLocalAddDetails("");
                            setTxnAddDetails("");
                            clearAllFilters();
                          }}
                          className="internal-txn-clear-params-btn"
                        >
                          Clear parameters
                        </button>
                      )}
                    </div>
                  ) : null
                ) : (
                  <div
                    id="txnViewallScrollContainer"
                    className={
                      isSidebarOpen ||
                      isPopoverOpen ||
                      isDistributionPopoverOpen
                        ? `h-full bg-black opacity-40`
                        : "w-full h-full"
                    }
                  >
                    {loading ? (
                      <div className="loader-container m-auto justify-center items-center flex w-full h-full">
                        <Loader />
                      </div>
                    ) : accumulatedData?.length > 0 ? (
                      <div className="flex flex-col items-center p-1 w-full h-full mt-[8px]">
                        <InternalTxnListViewCard
                          // handleRefresh={handleRefresh}
                          data={accumulatedData}
                          isSidebarOpen={isSidebarOpen}
                          page={currentPage}
                          isPopoverOpen={isPopoverOpen}
                          currentStatus={currentStatus}
                          isDistributionPopoverOpen={isDistributionPopoverOpen}
                          txnHistorytype={props?.txnHistorytype}
                          onClose={props?.onClose}
                          // refreshAllData={refreshAllData}
                          // refreshType={refreshType}
                          // setRefreshtype={setRefreshtype}
                          // setFailedRefreshId={setFailedRefreshId}
                          // failedRefreshId={failedRefreshId}
                          // successRefreshId={successRefreshId}
                          // setSuccessRefreshId={setSuccessRefreshId}
                        />
                      </div>
                    ) : !searchUserText && accumulatedData?.length === 0 ? (
                      <div
                        style={{
                          padding: accumulatedData.length === 0 && "60px",
                        }}
                        className={
                          isSidebarOpen ||
                          isPopoverOpen ||
                          isDistributionPopoverOpen
                            ? `internal-txn-noData h-full bg-black opacity-40 flex flex-col items-center justify-center m-auto`
                            : "internal-txn-noData flex flex-col items-center justify-center m-auto"
                        }
                      >
                        <img
                          style={{
                            marginTop:
                              props?.txnHistorytype === "ownerDetailTxnHistory"
                                ? "-100px"
                                : "0",
                            width: "320px",
                            height:
                              props?.txnHistorytype === "ownerDetailTxnHistory"
                                ? "220px"
                                : "224px",
                          }}
                          src={noaccount}
                          alt="No accounts found"
                          // className={
                          //   isSidebarOpen
                          //     ? "no-acc-image-opacity"
                          //     : "no-acc-image"
                          // }
                        />
                        <p
                        // className={
                        //   isSidebarOpen
                        //     ? "no-owneraccount-text-opacity"
                        //     : "no-owneraccount-text"
                        // }
                        >
                          No data found.
                        </p>
                        {(isFilterApplied || txnAmount || txnAddDetails) && (
                          <button
                            onClick={(e) => {
                              setTxnAmount("");
                              // setLocalAddDetails("");
                              setTxnAddDetails("");
                              clearAllFilters();
                            }}
                            className="internal-txn-clear-params-btn"
                          >
                            Clear parameters
                          </button>
                        )}
                      </div>
                    ) : (
                      <div
                        className=" internal-txn-noData flex flex-col items-center justify-center m-auto h-full"
                        style={{
                          opacity:
                            isSidebarOpen ||
                            isPopoverOpen ||
                            isDistributionPopoverOpen
                              ? 0.4
                              : 1,
                          padding: accumulatedData.length === 0 && "60px",
                        }}
                      >
                        <img
                          style={{
                            marginTop:
                              props?.txnHistorytype ===
                                "ownerDetailTxnHistory" ||
                              props?.txnHistorytype === "internalTxnHistory"
                                ? "-100px"
                                : "0",
                            width: "360px",
                            height:
                              props?.txnHistorytype ===
                                "ownerDetailTxnHistory" ||
                              props?.txnHistorytype === "internalTxnHistory"
                                ? "250px"
                                : "250px",
                          }}
                          src={noaccount}
                          alt="No accounts found"
                          className="internal-txn-no-acc-image"
                        />
                        <p
                          className="internal-txn-no-internalaccount-text"
                          style={{ width: "314px" }}
                        >
                          No data found.
                        </p>
                        {(isFilterApplied || txnAmount || txnAddDetails) && (
                          <button
                            onClick={(e) => {
                              setTxnAmount("");
                              //  setLocalAddDetails("");
                              setTxnAddDetails("");
                              clearAllFilters();
                            }}
                            className="internal-txn-clear-params-btn"
                          >
                            Clear parameters
                          </button>
                        )}
                      </div>
                    )}
                    {/* </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      ;
    </>
  );
};
export default InternalTransactionViewAll;
