import React, { useEffect, useState } from "react";
import "./RequireApproval.css";
import aprovaldenied from "../../../../assets/RejectIcons/reject_icon_red_with_white_circle.svg";
import aprovalaccept from "../../../../assets/CheckmarksIcons/approve_circle_green_checkmark.svg";
import xpentraloader from "../../../../assets/Beneficiary Icons/lodemore.gif";
import { ConvertBefiDate } from "../../../../utils/ConvertBefiDate";
import { beneRequiredApproval } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { beneWaitingApproved } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { useDispatch, useSelector } from "react-redux";
import DeclinedPopup from "./DeclinedPopup";
import InfiniteScroll from "react-infinite-scroll-component";
import NoAccountFound from "../../../../assets/Graphics/No account remaining to approved.svg";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import { Bounce, toast } from "react-toastify";
import { tooltipClasses, Tooltip } from "@mui/material";
import SkipVerification from "../../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import { checkUsertoken } from "../../../../redux/features/login/login";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import RejectingTransactionPopup from "./RejectingTransactionPopup/RejectingTransactionPopup";
import arrowleft from "../../../../assets/ArrowsIcons/ChevronLeftWhite.svg";
import arrowUp from "../../../../assets/ArrowsIcons/arrow_up_blue.svg";
import ArrowPlay from "../../../../assets/ArrowsIcons/arrow_right_blue_filled.svg";
import { useNavigate } from "react-router-dom";
import RefreshToast from "../../../../components/_utils/RefreshToast";
import { getRefreshTokenNew } from "../../../../redux/features/refreshToken/refreshToken";
import internalImg from "../../../../assets/Beneficiary Icons/internal_with_white_circle without fill.svg";
import approved_green from "../../../../assets/CheckmarksIcons/approved_green.svg";
import rejected_red from "../../../../assets/CloseIcons/rejected_red.svg";
import WhiteArrowRight from "../../../../assets/ArrowsIcons/arrow_right_white_filled.svg";
import arrow_right_blue_filled from "../../../../assets/Beneficiary Icons/arrow_right_blue_filled.svg";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";
import Loader from "../../../../components/Loader/Index";
import BeneAccDetailPagePopup from "../../../../components/Accounts/BeneAccDetailPagePopup";

const RequireApproval = (props) => {
  const dispatch = useDispatch();
  const [allAccounts, setAllAccounts] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [deniedId, SetdeniedId] = useState(null);
  const [loading, setLoading] = useState({});
  const [BeneWaitingStatus, SetBeneWaitingStatus] = useState(true);
  const [ErrorMessage, SetErrorMessage] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [allBeneTxnAccountsLoading, setAllBeneTxnAccountsLoading] =
    useState(false);
  const [approvedCount, setApprovedCount] = useState(0);
  const [preventMultiEnter, setPreventMultiEnter] = useState(false);
  const [BeneTxnDetailsId, SetBeneTxnDetailsId] = useState("");
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  // const [showRejectTnxPopup, setRejectTxnPopup] = useState(false);

  const navigate = useNavigate();

  const updateAllAccounts = (updatedAccounts) => {
    setAllAccounts(updatedAccounts);
  };

  let approvedpadCount;
  let deniedCount = 0;

  const { beneRequiredApprovalData } = useSelector(
    (state) => state.beneAllVerification
  );

  const { beneWaitingApprovalData } = useSelector((state) => {
    return state.beneAllVerification;
  });
  const services = useSelector((state) => state.refreshToken.services);

  useEffect(() => {
    if (currentPage === 1) {
      setAllBeneTxnAccountsLoading(true);
    }
    dispatch(checkUsertoken());
    dispatch(
      beneRequiredApproval({
        page_no: currentPage,
        type:
          props?.internalAccount == "internalAccount"
            ? "internalAccount"
            : undefined,
      })
    )
      .then((response) => {
        setAllAccounts((prevAccounts) => {
          const newData =
            (response?.payload?.beneficiary_accounts &&
              response?.payload?.beneficiary_accounts?.map((account) => ({
                ...account,
                loading: false,
                error: "false",
                message: "",
              }))) ||
            [];
          // setAllAccounts((prevAccounts) =>
          //   currentPage === 1 ? newData : [...prevAccounts, ...newData]
          // );
          return [...prevAccounts, ...newData];
        });
        setAllBeneTxnAccountsLoading(false);
      })
      .catch(() => {
        setAllBeneTxnAccountsLoading(false);
      });
  }, [dispatch, currentPage]);
  const handleOpenPopup = (id) => {
    setShowPopup(true);
    SetdeniedId(id);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    // setRejectTxnPopup(false);
  };

  const handleApprovalToastSuccess = () => {
    setApprovedCount((prevCount) => prevCount + 1);
    toast.success("Account approved successfully!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleApproval = async (id) => {
    setLoading((prevLoadingStates) => ({
      ...prevLoadingStates,
      [id]: true,
    }));
    RefreshToast("approvalaccountsRefresh");

    dispatch(checkUsertoken());
    const data = await dispatch(beneWaitingApproved(id));

    if (data?.payload?.errors?.user_unauthorized?.length > 0) {
      navigate("/feature_not_assigned", {
        state: {
          unavailableService: "BAAccount",
        },
      });
      return;
    }

    let errorMessage = "";
    if (data) {
      SetBeneWaitingStatus(data?.payload?.status);
      const errors = data?.payload?.errors;
      if (errors?.beneficiary_account?.[0]) {
        SetErrorMessage(errors.beneficiary_account[0]);
        errorMessage = errors.beneficiary_account[0];
      } else if (errors?.bank_account_number?.[0]) {
        SetErrorMessage(errors.bank_account_number[0]);
        errorMessage = errors.bank_account_number[0];
      } else {
        SetErrorMessage("An unknown error occurred.");
        errorMessage = "An unknown error occurred.";
      }
    }

    if (data) {
      const updatedData = allAccounts.map((item) => {
        if (item.id === id) {
          let lifecycleStatus = "";

          if (data.payload.status === true) {
            lifecycleStatus = "Approved";
          }
          if (data.payload.status === false) {
            lifecycleStatus = "error";
          }

          let message = "";
          if (data.payload.status === false) {
            const errors = data?.payload?.errors;
            if (errors?.beneficiary_account?.[0]) {
              message = errors.beneficiary_account[0];
            } else if (errors?.bank_account_number?.[0]) {
              message = errors.bank_account_number[0];
            }
          }

          return {
            ...item,
            approval_status:
              item.approval_status === "pending" ? lifecycleStatus : "pending",
            error:
              item.error === "false" && data?.payload.status === false
                ? "true"
                : "false",
            message:
              item.message === "" && data.payload.status === false
                ? message
                : "",
          };
        }

        return item;
      });

      setAllAccounts(updatedData);

      if (data?.payload.status === false) {
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    }

    setTimeout(() => {
      setLoading((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: false,
      }));
    }, 2000);

    setTimeout(() => {
      if (data.payload.status === true) {
        handleApprovalToastSuccess();
      }
    }, 2000);
  };

  const AllAprovalAcountCount =
    beneRequiredApprovalData?.summary?.pending_accounts_count ?? "00";

  allAccounts?.forEach((account) => {
    if (account.approval_status === "rejected" && account.error !== "true") {
      deniedCount++;
    }
  });

  approvedpadCount = approvedCount.toString().padStart(2, "0");
  deniedCount = deniedCount.toString().padStart(2, "0");

  const fetchMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const BeneAccountMoreDetailsPopup = async (id) => {
    SetBeneTxnDetailsId(id);
    setisDrawerOpen(!isDrawerOpen);
    // await dispatch(beneTxnAccountDetail(txnId));
  };
  const closeBeneAccountDetailModal = () => {
    setisDrawerOpen(false);
  };
  return (
    <>
      {showPopup && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
          <DeclinedPopup
            onClose={handleClosePopup}
            accountId={deniedId}
            allAccounts={allAccounts}
            updateAllAccounts={updateAllAccounts}
            status={"Benificiary"}
            setPreventMultiEnter={setPreventMultiEnter}
            type={props?.internalAccount}
            // showRejectTnxPopup={showRejectTnxPopup}
            // setRejectTxnPopup={setRejectTxnPopup}
          />
        </div>
      )}
      {isDrawerOpen && (
        <div
          className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80"
          onClick={() => {
            closeBeneAccountDetailModal();
          }}
        >
          <BeneAccDetailPagePopup
            closeBeneAccountDetailModal={closeBeneAccountDetailModal}
            bene_account_id={BeneTxnDetailsId}
            type={"bene_account_detail_modal"}
          />
        </div>
      )}
      <div
        id="webviewd"
        style={{
          height: showPopup ? "100vh" : "",
          overflowX: "hidden",
          overflowY: showPopup ? "hidden" : "",
        }}
      >
        <div id="RequireApprovalPage">
          <div className="ApprovalNavbar">
            <div className="Approvalsec1">
              <div className="Approvalsec2">
                <div className="approvalAcountTopbar">
                  <div
                    className="firstheaderApproval"
                    style={{ paddingLeft: "30px" }}
                  >
                    <div className="approvalParentdiv">
                      <p className="approvalchild1">
                        Σ {AllAprovalAcountCount}
                      </p>
                      <p className="approvalchild2">Total accounts</p>
                    </div>
                  </div>
                  <div className="SecondheaderApproval">
                    <div className="navbarleft">
                      <p className="approvedtag1">{approvedpadCount}</p>
                      <p className="approvedtag2"> Approved</p>
                    </div>
                    <div className="navbarright">
                      <p className="approvedtag3">{deniedCount}</p>
                      <p className="approvedtag4">Rejected</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="viewAllScrollContainerBar"
            style={{
              paddingBottom:
                beneRequiredApprovalData?.summary?.pending_accounts_count ===
                  undefined && "20px",
            }}
          >
            {allBeneTxnAccountsLoading ? (
              <div className="loader-container m-auto justify-center items-center flex">
                <Loader />
              </div>
            ) : beneRequiredApprovalData?.summary?.pending_accounts_count ===
              undefined ? (
              <div className="BeneAccount_NoAccountFound">
                <div className="BeneAccount_RightTextContentNoAccount">
                  <img src={NoAccountFound} alt="" />
                </div>
                <div className="BeneAccount_LeftTextContentNoAccount_acc">
                  {/* <span>No pending accounts found.</span> */}
                  <p>There are no more accounts that require your approval.</p>
                  <button
                    className="bene-acc-home-dashboard-btn"
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    Home dashboard
                  </button>
                </div>
              </div>
            ) : allAccounts?.length > 0 ? (
              <div className="scroll flex flex-col items-center justify-center w-full">
                {/* {showPopup && (
                  <DeclinedPopup
                    onClose={handleClosePopup}
                    accountId={deniedId}
                    allAccounts={allAccounts}
                    updateAllAccounts={updateAllAccounts}
                    status={"Benificiary"}
                  />
                )} */}
                <div
                  className={`scroll flex flex-col items-center justify-center w-full`}
                >
                  <InfiniteScroll
                    style={{ width: "inherit" }}
                    dataLength={allAccounts.length || []}
                    next={fetchMoreData}
                    hasMore={
                      currentPage <
                      Math.ceil(
                        beneRequiredApprovalData?.summary
                          ?.pending_accounts_count / pageSize
                      )
                    }
                    loader={
                      <div className="loader-container justify-center items-center flex">
                        <Loader />
                      </div>
                    }
                    scrollableTarget="viewAllScrollContainerBar"
                    scrollThreshold={0.8}
                  >
                    {allAccounts?.map((account, index) => (
                      <div className="approvedAcoutDetails" key={index}>
                        <div className="Approval_Acdiv1">
                          <div className="Approvalsection1">
                            <div className="approvaltext1">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                    textTransform: "capitalize",
                                  },
                                }}
                                title={account.name.toLowerCase()}
                              >
                                <p>{account.name}</p>
                              </Tooltip>
                              <span>+91 {account.contact_no}</span>
                            </div>
                            <div className="approvaltext2">
                              <p className="z1">Account name</p>
                              <span className="z2">Contact number</span>
                            </div>
                            <div className="approvaltext3">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                placement="bottom-start"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                                title={account.email_id}
                              >
                                <p>{account?.email_id.toLowerCase()}</p>
                              </Tooltip>
                              <span>Email ID</span>
                            </div>
                            {props?.internalAccount === "internalAccount" ? (
                              <div className="approvalDiv5_internal pt-4">
                                <div className="b">
                                  <p>Internal</p>
                                  <span>Account type</span>
                                </div>
                                <div className="b1">
                                  <img src={internalImg} alt="bank" />
                                </div>
                              </div>
                            ) : (
                              <div className="approvaltext4">
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                        [`& .${tooltipClasses.arrow}`]: {
                                          color: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={
                                    account.category
                                      ? account.category
                                      : "Not Assigned"
                                  }
                                  placement="bottom-start"
                                  PopperProps={{
                                    style: {
                                      maxWidth: "none",
                                    },
                                  }}
                                >
                                  <p>
                                    {account?.category
                                      ? account.category
                                      : account.category || "Not Assigned"}
                                  </p>
                                </Tooltip>

                                <span>Assigned categories</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="my-5 border-r border-solid border-Blue_3"></div>

                        <div className="Approval_Acdiv2">
                          {account.approval_status === "rejected" &&
                          account.error === "false" &&
                          !loading[account.id] ? (
                            <div className="flex justify-between w-[21.40rem] h-full bg-Blue_8 ">
                              <div className="flex flex-col gap-[6px] mt-[15px] ml-[27px] overflow-hidden">
                                <p className="w-[7.5rem] h-[16px] font-normal text-[15px] leading-[16px] text-Primary_grey">
                                  Rejected reason
                                </p>

                                {account.rejection_reason &&
                                account.rejection_reason.length > 300 ? (
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={account.rejection_reason}
                                  >
                                    <span
                                      className="w-[17.5rem] line-clamp-[7] text-left text-[13px] font-normal overflow-hidden font-sans  text-[#F9F9F9] opacity-100"
                                      style={{
                                        letterSpacing: "0.5px",
                                        wordWrap: "break-word",
                                        // border: "1px solid red",
                                      }}
                                    >
                                      {account.rejection_reason}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  <span
                                    className="w-[17.5rem]  h-[16px]  text-left text-[13px] font-normal  font-sans tracking-normal  text-Primary_white  opacity-100 overflow-ellipsis"
                                    style={{
                                      letterSpacing: "0.5px",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {account.rejection_reason}
                                  </span>
                                )}
                              </div>
                              {/* <div className="w-[1.3px] h-[157px] bg-Blue_3 opacity-[0.6] mt-[20px]"></div> */}
                            </div>
                          ) : (
                            <div className="Approvalsection2 p-[5px]  ">
                              <div className="approvalDiv1">
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                        [`& .${tooltipClasses.arrow}`]: {
                                          color: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={account.bank_account_holder_full_name.toLowerCase()}
                                  placement="bottom"
                                  PopperProps={{
                                    style: {
                                      maxWidth: "none",
                                      textTransform: "capitalize",
                                    },
                                  }}
                                >
                                  <p>
                                    {account.bank_account_holder_full_name.toLowerCase()}
                                  </p>
                                </Tooltip>

                                <span
                                  className="approvalDiv1span"
                                  style={{
                                    marginRight: "15px",
                                  }}
                                >
                                  {account.pan_no}
                                </span>
                              </div>
                              <div className="approvalDiv2">
                                <span className="n1">
                                  Bank account holder name
                                </span>
                                <span className="n2">PAN number</span>
                              </div>
                              <div className="approvalDiv3">
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={formatAccountNumber(
                                    account.bank_acc_no
                                  )}
                                >
                                  <p>
                                    {formatAccountNumber(account.bank_acc_no)}
                                  </p>
                                </Tooltip>
                                <span
                                  style={{
                                    marginRight: account.gstin_detail
                                      ? "62px"
                                      : "15px",
                                  }}
                                >
                                  {account.gstin_detail
                                    ? account.gstin_detail
                                    : "Not given"}{" "}
                                </span>
                              </div>
                              <div className="approvalDiv4">
                                <p>Account number</p>
                                <span>GSTIN</span>
                              </div>

                              <div className="approvalDiv5">
                                <div className="b">
                                  <p>{account.ifsc_code}</p>
                                  <span>IFSC code</span>
                                </div>
                                <div className="b1">
                                  <img
                                    src={require("../../../../assets/IfscLogo/" +
                                      IfscCodeMapper(account.ifsc_code))}
                                    alt="bank"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="my-5 border-r border-solid border-Blue_3"></div>
                        <div className="Approval_Acdiv3">
                          {account.approval_status === "pending" &&
                            !loading[account.id] && (
                              <div className="Approvalsection3">
                                <div className="AprovalpageContainx1">
                                  <span className="createdOnDate">
                                    {ConvertBefiDate(account.created_on)}
                                  </span>
                                  <div
                                    className="moreDetailsButton flex flex-row justify-center items-center cursor-pointer gap-x-2.5"
                                    onClick={() => {
                                      BeneAccountMoreDetailsPopup(
                                        // "BeneTxnRequiredApproval",
                                        account.id
                                      );
                                    }}
                                  >
                                    <span className="moreDetailsText">
                                      More details
                                    </span>
                                    <img
                                      src={arrowUp}
                                      className="moreDetails_ArrowButton rotate-90"
                                    />
                                  </div>
                                </div>
                                <div className="AprovalpageContainx2">
                                  {/* <span className="b1">Requested by</span> */}
                                  <span className="b2">Initiated on</span>
                                </div>

                                <div className="logo">
                                  <div className="logo1">
                                    {account.approval_status === "pending" &&
                                      !loading[account.id] && (
                                        <>
                                          <button
                                            className="Approvalleftside hover:bg-Blue_8_hover"
                                            onClick={() => {
                                              handleOpenPopup(account.id);
                                            }}
                                          >
                                            <img src={aprovaldenied} alt="" />
                                            <p>Reject</p>
                                          </button>

                                          {/* <div className="approvaldivider">
                                        <p className="divider-sec"></p>
                                      </div> */}
                                          <div className="my-2.5 border-r border-solid border-Blue_3"></div>
                                          <button
                                            className="Approvalrightside"
                                            onClick={() => {
                                              handleApproval(account.id);
                                            }}
                                          >
                                            <img src={aprovalaccept} alt="" />
                                            <p>Approve</p>
                                          </button>
                                        </>
                                      )}
                                  </div>
                                </div>
                              </div>
                            )}
                          {loading[account.id] && (
                            <div className="Approvalsection3">
                              <div className="AprovalpageContainx1">
                                <span className="createdOnDate">
                                  {ConvertBefiDate(account.created_on)}
                                </span>
                                <div
                                  className="moreDetailsButton flex flex-row justify-center items-center cursor-pointer gap-x-2.5"
                                  onClick={() => {
                                    BeneAccountMoreDetailsPopup(
                                      // "BeneTxnRequiredApproval",
                                      account.id
                                    );
                                  }}
                                >
                                  <span className="moreDetailsText">
                                    More details
                                  </span>
                                  <img
                                    src={arrowUp}
                                    className="moreDetails_ArrowButton"
                                  />
                                </div>
                              </div>
                              <div className="AprovalpageContainx2">
                                {/* <span className="b1">Requested by</span> */}
                                <span className="b2">Initiated on</span>
                              </div>

                              <div className="logo">
                                <div className="logo1">
                                  {account.approval_status === "pending" &&
                                    !loading[account.id] && (
                                      <>
                                        <button
                                          className="Approvalleftside hover:bg-Blue_8_hover"
                                          onClick={() => {
                                            handleOpenPopup(account.id);
                                          }}
                                        >
                                          <img src={aprovaldenied} alt="" />
                                          <p>Reject</p>
                                        </button>

                                        {/* <div className="approvaldivider">
                                        <p className="divider-sec"></p>
                                      </div> */}
                                        <div className="my-2.5 border border-solid border-Blue_3"></div>
                                        <button
                                          className="Approvalrightside"
                                          onClick={() => {
                                            handleApproval(account.id);
                                          }}
                                        >
                                          <img src={aprovalaccept} alt="" />
                                          <p>Approve</p>
                                        </button>
                                      </>
                                    )}

                                  {loading[account.id] && (
                                    <div className="w-8 h-7 m-auto">
                                      <Loader />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          {account.approval_status === "Approved" &&
                            account.error === "false" &&
                            beneWaitingApprovalData &&
                            !loading[account.id] && (
                              <div className="w-[21.38rem] h-full bg-Blue_8 flex gap-[20px]  rounded-tr-[30px] rounded-br-[30px] pl-[20px] ">
                                <div className=" w-[41px]  h-[67px] mt-[30px]">
                                  <img src={approved_green} alt="" />
                                </div>
                                <div className="">
                                  <div className="flex flex-col gap-[10px] w-[14.625rem] h-[67px] opacity-100 mt-[30px]">
                                    <p className="h-[22px] text-left font-semibold text-[16px] text-Primary_white opacity-100">
                                      Approved
                                    </p>
                                    <span className="w-[15rem] font-normal text-[14px] text-Primary_white opacity-100">
                                      This account has been approved.
                                    </span>
                                  </div>
                                  <div
                                    className="flex justify-between items-center w-[13.3125rem] h-[36px] cursor-pointer bg-Blue_3 rounded-[10px] opacity-100 mt-[29px]"
                                    onClick={() => {
                                      BeneAccountMoreDetailsPopup(
                                        // "BeneTxnRequiredApproval",
                                        account.id
                                      );
                                    }}
                                  >
                                    <p className="w-[5.9375rem] h-[17px] ml-[10px] font-normal text-[12px]  text-Primary_white opacity-100">
                                      View account
                                    </p>
                                    <img
                                      className="w-[14px] h-[14px] opacity-100 mr-[10px] rotate-180"
                                      src={arrowleft}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                          {account.approval_status === "rejected" &&
                            account.error === "false" &&
                            !loading[account.id] && (
                              <div className="w-[21.38rem] h-full bg-Blue_8 flex gap-[20px]  rounded-tr-[30px] rounded-br-[30px] pl-[20px] ">
                                <div className=" w-[41px]  h-[67px] mt-[30px]">
                                  <img src={rejected_red} alt="" />
                                </div>
                                <div className="">
                                  <div className="flex flex-col gap-[10px] w-[14.625rem] h-[67px] opacity-100 mt-[30px]">
                                    <p className="h-[22px] text-left font-semibold text-[16px] text-Primary_white opacity-100">
                                      Rejected
                                    </p>
                                    <span className="w-[15rem] font-normal text-[14px] text-Primary_white opacity-100">
                                      This account has been rejected!
                                    </span>
                                  </div>
                                  <div
                                    className="flex justify-between items-center w-[13.3125rem] h-[36px] cursor-pointer bg-Blue_3 rounded-[10px] opacity-100 mt-[29px]"
                                    onClick={() => {
                                      BeneAccountMoreDetailsPopup(
                                        // "BeneTxnRequiredApproval",
                                        account.id
                                      );
                                    }}
                                  >
                                    <p className="w-[5.9375rem] h-[17px] ml-[10px] font-normal text-[12px]  text-Primary_white opacity-100">
                                      View account
                                    </p>
                                    <img
                                      className="w-[14px] h-[14px] opacity-100 mr-[10px] rotate-180"
                                      src={arrowleft}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                          {account.approval_status === "error" &&
                            account.error === "true" &&
                            !loading[account.id] && (
                              <div className="flex  w-[21.375rem] h-full bg-Blue_8  pl-[20px]  rounded-tr-[30px] rounded-br-[30px] gap-[15px]">
                                <div className="w-[40px] h-[40px]  mt-[30px]">
                                  <img src={SkipVerification} alt="" />
                                </div>
                                <div className="w-[223px] h-[131px]">
                                  <div className="flex flex-col gap-[10px] w-[14.625rem] opacity-100 mt-[30px] ">
                                    <p className="w-[13.9375rem] h-[22px] text-left font-semibold text-Primary_white text-[16px] leading-[22px]">
                                      Failed to take action!
                                    </p>
                                    <div className="w-[15.125rem]  line-clamp-2   text-Primary_white   whitespace-wrap overflow-ellipsis">
                                      <span className="     font-normal     text-[#FFFFFF] text-[14px] leading-[19px]">
                                        {account.message && (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                      marginRight: "4rem",
                                                    },
                                                },
                                              },
                                            }}
                                            title={account?.message}
                                          >
                                            <p className="w-[227px]   line-clamp-2   whitespace-wrap overflow-ellipsis   text-[14px] text-[#F9F9F9] opacity-100">
                                              {account?.message}
                                            </p>
                                          </Tooltip>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="flex justify-between items-center w-[13.3125rem] h-[36px] cursor-pointer bg-Blue_3 rounded-[10px] opacity-100 mt-[29px]"
                                    onClick={() => {
                                      BeneAccountMoreDetailsPopup(
                                        // "BeneTxnRequiredApproval",
                                        account.id
                                      );
                                    }}
                                  >
                                    <p className="w-[5.9375rem] h-[17px] ml-[10px] font-normal text-[12px]  text-Primary_white opacity-100">
                                      View account
                                    </p>
                                    <img
                                      className="w-[14px] h-[14px] opacity-100 mr-[10px] rotate-180"
                                      src={arrowleft}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div
        id="mobileviewd"
        style={{
          height: showPopup ? "100vh" : "",
          overflowX: "hidden",
          overflowY: showPopup ? "hidden" : "",
        }}
      >
        <div className="bene_approval_heder_containerm">
          <div className="allBeneapprovalheadertext">
            <div
              className="allBeneapprovalheaderarrow"
              onClick={() => navigate(-1)}
              style={{ height: "100%", padding: "20px", width: "auto" }}
            >
              <img src={arrowleft} alt="" className="h-[16px] w-[16px]" />
            </div>
            <div className="allBeneapprovalheadertextm py-[10px]">
              <div className="headingAccountDetail text-[#f9f9f9]">
                {props?.internalAccount === "internalAccount"
                  ? "Internal"
                  : "Beneficiary"}
              </div>
              <div className="headertextbottomv ">Account approval</div>
            </div>
          </div>
        </div>
        <div className="bene_approval_containerm">
          <div className="allBeneapprovaltext">
            <p>
              Total Accounts :{" "}
              <span style={{ color: "#f9f9f9", fontWeight: "bold" }}>
                {AllAprovalAcountCount}
              </span>
            </p>
          </div>
          <div className="SecondheaderApprovalm">
            <div className="">
              <p
                className="approvedtag2"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Open Sans",
                  color: "#f9f9f9",
                }}
              >
                {" "}
                Approved :{" "}
                <span style={{ color: "#3cb371", fontWeight: "bold" }}>
                  {approvedpadCount}
                </span>
              </p>
            </div>
            <div className="">
              <p
                className="approvedtag4"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Open Sans",
                  color: "#f9f9f9",
                }}
              >
                Rejected :{" "}
                <span style={{ color: "#ff5860", fontWeight: "bold" }}>
                  {deniedCount}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            overflow:
              beneRequiredApprovalData?.summary?.pending_accounts_count ===
              undefined
                ? "hidden"
                : "auto",
          }}
          id="BeneviewAllScrollContainerBarbene"
        >
          {allBeneTxnAccountsLoading ? (
            <div className=" justify-center items-center flex flex-col h-[69vh]">
              <Loader />
            </div>
          ) : // </div>
          beneRequiredApprovalData?.summary?.pending_accounts_count ===
            undefined ? (
            <div className="BeneAccount_NoAccountFound">
              <div className="BeneAccount_RightTextContentNoAccount">
                <img
                  src={NoAccountFound}
                  id="mob-bene-acc-no-data-sigle"
                  alt=""
                />
              </div>
              <div
                className="BeneAccount_LeftTextContentNoAccount_acc text-Font_16"
                style={{ width: "auto" }}
              >
                {/* <span>No pending accounts found.</span> */}
                <p>There are no more accounts awaiting your approval.</p>
                <button
                  className="bene-acc-home-dashboard-btn"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  Home dashboard
                </button>
              </div>
            </div>
          ) : allAccounts?.length > 0 ? (
            <div className="scroll flex flex-col items-center justify-center w-full">
              <div
                className={`scroll flex flex-col items-center justify-center w-full`}
              >
                <InfiniteScroll
                  style={{ width: "inherit" }}
                  dataLength={allAccounts.length || []}
                  next={fetchMoreData}
                  hasMore={
                    currentPage <
                    Math.ceil(
                      beneRequiredApprovalData?.summary
                        ?.pending_accounts_count / pageSize
                    )
                  }
                  loader={
                    <img
                      className="w-[50px] h-[50px] mx-auto"
                      src={dataLoading}
                      alt="Loading..."
                    />
                  }
                  scrollableTarget="BeneviewAllScrollContainerBarbene"
                  scrollThreshold={0.8}
                >
                  {allAccounts?.map((account, index) => (
                    <div className="approvedAcoutDetailsm" key={index}>
                      <div className="ApprovalAcdivm1">
                        <div
                          className="Approvalsection1"
                          style={{
                            marginBottom:
                              props?.internalAccount === "internalAccount"
                                ? "0px"
                                : "12px",
                          }}
                        >
                          <div className="flex justify-between items-start p-[15px]">
                            <div className="flex flex-col gap-[2px] approvaltext1m w-[50%]">
                              {/* <p className="w-[90%] h-[19px] overflow-hidden whitespace-nowrap text-left text-[14px] leading-[19px] font-normal text-[#fcfcfc] capitalize truncate"> */}
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                placement="bottom-end"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                    textTransform: "capitalize",
                                  },
                                }}
                                title={account.name.toLowerCase()}
                              >
                                <p>{account.name}</p>
                              </Tooltip>
                              {/* </p> */}
                              <p className="mt-[2px] text-left text-xs text-[#dadada]">
                                Account name
                              </p>
                            </div>
                            <div className="flex flex-col gap-[2px] items-end w-[50%]">
                              <p className="text-right text-[#f9f9f9] text-[14px]">
                                {"+91" + "  " + account.contact_no}
                              </p>
                              <p className="mt-[2px] text-right text-xs text-[#dadada]">
                                Contact number
                              </p>
                            </div>
                          </div>
                          <div className="p-[15px] pt-0 flex flex-col gap-[2px]">
                            <p className="w-[100%] text-left text-[14px] leading-[19px] font-normal text-[#f9f9f9] opacity-100 truncate">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                placement="bottom-start"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                                title={account.email_id}
                              >
                                <p className="w-[100%] truncate">
                                  {account?.email_id.toLowerCase()}
                                </p>
                              </Tooltip>
                            </p>
                            <p className="text-left text-[12px] leading-[17px] font-normal text-[#dadada] opacity-100">
                              Email ID
                            </p>
                          </div>

                          {props?.internalAccount === "internalAccount" ? (
                            <div className="approvalDiv5_internal mt-0 p-[15px] pt-0">
                              <div className="flex flex-col gap-[2px]">
                                <p style={{ marginLeft: 0 }}>Internal</p>
                                <span
                                  style={{
                                    marginLeft: 0,
                                    width: "auto",
                                    marginTop: "0",
                                  }}
                                >
                                  Account Type
                                </span>
                              </div>
                              <div>
                                <img
                                  src={internalImg}
                                  alt="bank"
                                  className="h-[30px] w-[30px] m-0"
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="approvaltext4">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={
                                  account.category
                                    ? account.category
                                    : "Not Assigned"
                                }
                                placement="bottom-start"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                              >
                                <p
                                  style={{
                                    marginTop: "10px",
                                    marginLeft: "15px",
                                    paddingRight: "16px",
                                    width: "inherit",
                                  }}
                                >
                                  {account?.category
                                    ? account.category
                                    : account.category || "Not Assigned"}
                                </p>
                              </Tooltip>

                              <span
                                style={{
                                  marginLeft: "15px",
                                }}
                              >
                                Assigned categories
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className="w-full h-full text-white"
                        style={{ borderBottom: "1px solid #1D3A6D" }}
                      >
                        <div className="w-full h-full my-[15px]">
                          <div className="flex justify-between px-[15px]">
                            <div className="approvalDiv1m">
                              {/* <p className="mt-5 text-left text-[14px] leading-[19px] font-normal text-[#fcfcfc] capitalize truncate... overflow-hidden whitespace-nowrap"> */}
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={account.bank_account_holder_full_name.toLowerCase()}
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                    textTransform: "capitalize",
                                  },
                                }}
                              >
                                <p style={{ marginTop: "0" }}>
                                  {account.bank_account_holder_full_name.toLowerCase()}
                                </p>
                              </Tooltip>
                              {/* </p> */}
                              <p className="mt-[2px] text-left text-[12px] leading-[17px] font-normal text-[#dadada]">
                                Bank account holder name
                              </p>
                            </div>
                            <div className="text-right">
                              <p className="text-[14px] leading-[19px] font-normal text-[#f9f9f9]">
                                {account.pan_no}
                              </p>
                              <p className="mt-[2px] text-[12px] leading-[17px] font-normal text-[#dadada]">
                                PAN number
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-between p-[15px]">
                            <div className="flex-1">
                              <p className="h-[19px] overflow-hidden text-ellipsis text-[14px] leading-[19px] font-normal text-[#f9f9f9]">
                                {formatAccountNumber(account.bank_acc_no)}
                              </p>
                              <p className="mt-[2px] h-[17px] mt-1 text-left text-[12px] leading-[17px] font-normal text-[#dadada]">
                                Account number
                              </p>
                            </div>
                            <div className="flex-1 text-right">
                              <p
                                className="h-[19px] text-[14px] leading-[19px] font-normal text-[#f9f9f9]"
                                style={{
                                  marginRight: account.gstin_detail ? "" : "",
                                }}
                              >
                                {account.gstin_detail || "Not given"}
                              </p>
                              <p className="mt-[2px] h-[17px] text-[12px] leading-[17px] font-normal text-[#dadada] text-right">
                                GSTIN
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-between px-[15px] py-0">
                            <div className="flex flex-col gap-[2px]">
                              <p className="text-[14px] leading-[19px] font-normal text-[#f9f9f9]">
                                {account.ifsc_code}
                              </p>
                              <p className="mt-[2px] text-[12px] leading-[17px] font-normal text-[#dadada]">
                                IFSC code
                              </p>
                            </div>
                            <div className="w-[100px] mr-[-15px] h-[31px] bg-[#f9f9f9] py-[10px] px-[20px] rounded-tl-[20px] rounded-bl-[20px] flex justify-center items-center">
                              <img
                                className="w-[60px] h-[20px]"
                                src={require("../../../../assets/IfscLogo/" +
                                  IfscCodeMapper(account.ifsc_code))}
                                alt="bank"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-full">
                        <div className="flex justify-between text-sm p-[15px]">
                          <div className="flex flex-col gap-[2px] items-start approvalRequestedm">
                            <p className="text-[#f9f9f9]">
                              {ConvertBefiDate(account.created_on)}
                            </p>
                            <p className="mt-[2px] text-[12px] leading-[17px] font-normal text-[#dadada]">
                              Initiated on
                            </p>
                          </div>
                          <div
                            className="w-[100px] mr-[-15px] h-[31px] bg-Primary_white py-[10px]  rounded-tl-[20px] rounded-bl-[20px] flex justify-center items-center cursor-pointer"
                            onClick={() => {
                              navigate(
                                `/accounts/beneficiary_account/details/${account.id}`,
                                {
                                  state: {
                                    type: "txn_single_approval",
                                  },
                                }
                              );
                            }}
                          >
                            <div className="flex justify-center">
                              <p className="text-Blue_3 pr-[3px] font-600 font-semibold text-Font_10">
                                More details
                              </p>
                              <img
                                className=" w-[11.22px] h-[20.24px]"
                                src={ArrowPlay}
                              />
                            </div>
                          </div>
                        </div>

                        <div className>
                          <div
                            className={`bg-[#506994] rounded-[20px] flex justify-between items-center w-full min-h-[70px]`}
                          >
                            {account.approval_status === "pending" &&
                              !loading[account.id] && (
                                <>
                                  <button
                                    className="flex flex-col items-center justify-center gap-1 w-1/2 pt-4 pb-4"
                                    onClick={() => {
                                      handleOpenPopup(account.id);
                                    }}
                                  >
                                    <img
                                      src={aprovaldenied}
                                      alt="Reject"
                                      className="w-10 h-10"
                                    />
                                    <p className="text-[#f9f9f9] text-xs">
                                      Reject
                                    </p>
                                  </button>
                                  <div
                                    className=""
                                    style={{
                                      border: "1px solid #1d3a6d",
                                      height: "60px",
                                    }}
                                  ></div>
                                  <button
                                    className="flex flex-col items-center justify-center gap-1 w-1/2 pt-4 pb-4"
                                    onClick={() => {
                                      handleApproval(account.id);
                                    }}
                                  >
                                    <img
                                      src={aprovalaccept}
                                      alt="Approve"
                                      className="w-10 h-10"
                                    />
                                    <p className="text-[#f9f9f9] text-xs">
                                      Approve
                                    </p>
                                  </button>
                                </>
                              )}

                            {loading[account.id] && (
                              <div className="flex justify-center w-full">
                                <Loader />
                              </div>
                            )}

                            {account.approval_status === "Approved" &&
                              account.error === "false" &&
                              beneWaitingApprovalData &&
                              !loading[account.id] && (
                                <div className="flex items-center bg-[#3cb371] text-white w-full rounded-[20px] py-5 px-5 min-h-[70px]">
                                  <img
                                    src={aprovalaccept}
                                    alt="Approved"
                                    className="w-10 h-10 mr-3"
                                  />
                                  <div>
                                    <p className="font-semibold text-sm">
                                      Approved!
                                    </p>
                                    <p className="text-xs">
                                      Account has been approved.
                                    </p>
                                  </div>
                                </div>
                              )}

                            {account.approval_status === "error" &&
                              account.error === "true" &&
                              !loading[account.id] && (
                                <div className="flex items-center bg-[#bb5353] text-white w-full rounded-lg py-5 px-5 min-h-[70px]">
                                  <img
                                    src={SkipVerification}
                                    alt="Failed"
                                    className="w-10 h-10 mr-3"
                                  />
                                  <div className="w-[90%]">
                                    <span>Failed to take action!</span>
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                            },
                                            [`& .${tooltipClasses.arrow}`]: {
                                              color: (theme) =>
                                                theme.palette.common.black,
                                            },
                                          },
                                        },
                                      }}
                                      title={
                                        account.message
                                          ? account.message
                                          : "Not given"
                                      }
                                      placement="bottom"
                                      PopperProps={{
                                        style: {
                                          maxWidth: "none",
                                          textTransform: "capitalize",
                                        },
                                      }}
                                    >
                                      <p className="text-xs  failed-action-message">
                                        {account.message}
                                      </p>
                                    </Tooltip>
                                  </div>
                                </div>
                              )}

                            {account.approval_status === "rejected" &&
                              account.error === "false" &&
                              !loading[account.id] && (
                                <div className="flex items-center bg-[#ff5860] text-white w-full rounded-[20px] py-5 px-5 min-h-[70px]">
                                  <img
                                    src={aprovaldenied}
                                    alt="Rejected"
                                    className="w-10 h-10 mr-4"
                                  />
                                  <div>
                                    <p className="font-semibold text-sm">
                                      Rejected!
                                    </p>
                                    <p className="text-xs">
                                      Account has been rejected.
                                    </p>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default RequireApproval;
